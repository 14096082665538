import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "../assets/mef-slike/404.png";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
  <>
    <Helmet>
      {/* Meta Tags */}
      <title>{t("notFound.metaTitle")}</title>
      <meta name="description" content={ t("notFound.metaDescription")} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={t("notFound.ogMetaTitle")}/>
      <meta property="og:description" content={t("meta.og.title")} />
      <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
      <meta property="og:url" content={window.location.origin}/>
    </Helmet>

    <Container className="vh-100 d-flex align-items-center justify-content-center">
      <Row className="text-center">
        <Col>
          <img
            src={NotFoundImage}
            alt="not_found_image"
            style={{ display: "block", margin: "0 auto", width: "100%" }}
          />

          <h1 className="mb-3 text-white my-4">{t("notFound.title")}</h1>
          <p className="text-white mb-5"></p>
          <Button
            variant="primary"
            className="text-white"
            onClick={handleGoBack}
          >
            {t("notFound.backToHomepage")}
          </Button>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default NotFound;
