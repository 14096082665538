import React, { useEffect, useState } from "react";
import "../../styles/global.css";
import { fetchDekanatIzjave } from "../../services/apiService";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import DekanatStatementsShared from "../../components/DekanatIzjaveShared";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const DekanatStatements = () => {
  const [statements, setStatements] = useState([]);
  const { t } = useTranslation(); // Use useTranslation hook to get translation function

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchDekanatIzjave(); // Fetch data using the service function
        setStatements(data);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching statements:", error);
      }
    };

    loadData(); // Call the function to load the data
  }, []);

  return (
    <div className="container__wrapper bg-light" id="rijeciuprave">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-2 mb-4">
          <div className="col-md-9">
            <h2 className="section_title">
              {t("dekanat.title")} {/* Use translation here */}
            </h2>
            <p className="section_subtitle">
              {t("dekanat.subtitle")} {/* Use translation here */}
            </p>
          </div>

          <Link
            className="section__buttons mt-2"
            to={`/fakultet/nastavno-osoblje`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ButtonComponent
              label={t("dekanat.button")}
              path="/dekanat-izjave"
              backgroundColor={"#051435"}
              color={"#FFFFFF"}
            />
          </Link>
        </div>
        <DekanatStatementsShared />
      </div>
    </div>
  );
};

export default DekanatStatements;
