// src/pages/Pocetna.js
import React from "react";
import PocetnaBaner from "./Pocetna/PocetnaBaner";
import SectionNavbar from "../components/NavigacijaSekcije";
import PrvaSekcija from "./Pocetna/PrvaSekcija";
import Onama from "./Pocetna/Onama";
import Vijesti from "./Pocetna/Vijesti";
import Obavijesti from "./Pocetna/Obavijesti";
import Projekti from "./Pocetna/Projekti";
import { Outlet } from "react-router-dom";
import DekanatIzjave from "./Pocetna/DekanatIzjave";
import Ciklusi from "./Pocetna/Ciklusi";

import Odsjeci from "./Pocetna/Odsjeci";
import Kontakt from "./Pocetna/Kontakt";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";


function Pocetna() {
  const bannerRef = useRef(null); // Ref for Onama section
  const { t } = useTranslation();
  
  const items = [
    { nameKey: "navbarPocetna.aboutUs", link: "#onama" },
    { nameKey: "navbarPocetna.news", link: "#novosti" },
    { nameKey: "navbarPocetna.announcements", link: "#obavjestenja" },
    { nameKey: "navbarPocetna.projects", link: "#projekti" },
    { nameKey: "navbarPocetna.deansWord", link: "#rijeciuprave" },
    { nameKey: "navbarPocetna.cycles", link: "#ciklusi" },
    { nameKey: "navbarPocetna.departments", link: "#odsjeci" },
    { nameKey: "navbarPocetna.contact", link: "#kontakt" },
  ];

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("pocetna.metaTitle")}</title>
        <meta name="description" content={ t("pocetna.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("meta.og.title")}/>
        <meta property="og:description" content={t("meta.og.description")} />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>


      <div ref={bannerRef}>
        <PocetnaBaner />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />
      <PrvaSekcija />
      <Onama />
      <Vijesti />
      <Obavijesti />
      <Projekti />
      <DekanatIzjave />
      <Ciklusi />
      <Odsjeci />
      <Kontakt />
      <Outlet />
    </div>
  );
}

export default Pocetna;
