import React, { useEffect, useState } from "react";
import { FaEnvelope, FaPhone, FaBuilding } from "react-icons/fa";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";
import "../../styles/global.css";
import { fetchDekanat } from "../../services/apiService";
import style from "./DekanatKartice.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DekanatCards = () => {
  const [dekanatData, setDekanatData] = useState([]);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchDekanat();
        setDekanatData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    loadData();
  }, [i18n.language]);

  return (
    <div className="bg-light container__wrapper">
      <div className="container">
        <div className="row g-4 justify-content-between">
          {dekanatData.map((item, index) => (
            <div
              key={index}
              className="col-md-6 d-flex justify-content-center"
              onClick={() => {
                window.location.href = `/fakultet/nastavno-osoblje/${item.staff.slug}`;
              }}
            >
              <div class={style.dekanatCard}>
                <div class={style.dekanatPosition}>{item.position}</div>

                <div className="d-flex align-items-center mb-3">
                  <img
                    className="professor-image avatar me-3"
                    src={item.staff.user.image}
                    alt={`${item.staff.user.first_name} ${item.staff.user.last_name}`}
                  />
                  <div>
                    <h5 className="mb-0">
                      {item.staff.short_rank} {item.staff.user.first_name}{" "}
                      {item.staff.user.last_name}
                    </h5>
                  </div>
                </div>

                <hr style={{ borderColor: "#DDDDDD" }} />

                <div className="mb-2">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Email</Tooltip>}
                  >
                    <div target="_blank" style={{ display: "inline-block" }}>
                      <FaEnvelope className="me-2" />
                    </div>
                  </OverlayTrigger>
                  {item.staff.user.email}
                </div>

                <div className="mb-2">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {localStorage.getItem("language") === "en"
                          ? "Phone"
                          : "Telefon"}
                      </Tooltip>
                    }
                  >
                    <div target="_blank" style={{ display: "inline-block" }}>
                      <FaPhone className="me-2" />
                    </div>
                  </OverlayTrigger>
                  {item.staff.phone}
                </div>

                <div className="mb-2">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {localStorage.getItem("language") === "en"
                          ? "Office"
                          : "Kabinet"}
                      </Tooltip>
                    }
                  >
                    <div target="_blank" style={{ display: "inline-block" }}>
                      <FaBuilding className="me-2" />
                    </div>
                  </OverlayTrigger>
                  {item.staff.office || "N/A"}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pt-4 pb-4 ps-2">
          <Link
            to={`/fakultet/nastavno-osoblje`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {" "}
            <ButtonComponent
              label={t("oFakultetuStranica.staffSection.Staff list →")}
              color="#000"
              backgroundColor="#9FDAF8"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DekanatCards;
