import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../../components/ButtonComponent";
import { sendContactForm } from "../../../services/apiService";
import style from "./Map.module.css";
import { toast } from "react-toastify";
import "../../../styles/global.css";

const MapComponent = () => {
  const { t } = useTranslation();
  const position = [43.8539, 18.3961];
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
    subject: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = t("map.errors.emailRequired");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t("map.errors.emailInvalid");
    }
    if (!formData.message) {
      newErrors.message = t("map.errors.messageRequired");
    } else if (formData.message.length < 10) {
      newErrors.message = t("map.errors.messageMinLength");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const requestData = {
          email: formData.email,
          message: formData.message,
        };

        if (formData.fullName) {
          requestData.fullName = formData.fullName;
        }

        if (formData.subject) {
          requestData.subject = formData.subject;
        }

        const response = await sendContactForm(requestData);

        toast.success(t("map.successMessage"), {
          autoClose: 1500,
        });

        setFormData({ fullName: "", email: "", message: "", subject: "" });
      } catch (error) {
        toast.error(t("map.errorMessage"), {
          autoClose: 1500,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 mb-4">
          <MapContainer
            center={position}
            zoom={17}
            className={style.mapContainer}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup>{t("map.mapPopup")}</Popup>
            </Marker>
          </MapContainer>
        </div>

        <div className="col-12 col-md-6">
          <h4 className="fs-2 mb-3">{t("map.contactUs")}</h4>
          <form onSubmit={handleSubmit} noValidate>
            <div className="mb-3">
              <label
                htmlFor="fullName"
                className="form-label section_subtitle mb-2"
              >
                {t("map.fullNameLabel")}
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.fullName ? "is-invalid" : ""
                }`}
                id="fullName"
                placeholder={t("map.fullNamePlaceholder")}
                value={formData.fullName}
                onChange={handleInputChange}
              />
              {errors.fullName && (
                <div className="invalid-feedback">{errors.fullName}</div>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="email"
                className="form-label section_subtitle mb-2"
              >
                {t("map.emailLabel")}
              </label>
              <input
                type="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                id="email"
                placeholder={t("map.emailPlaceholder")}
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="subject"
                className="form-label section_subtitle mb-2"
              >
                {t("map.subjectLabel")}
              </label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder={t("map.subjectPlaceholder")}
                value={formData.subject}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label
                htmlFor="message"
                className="form-label section_subtitle mb-2"
              >
                {t("map.messageLabel")}
              </label>
              <textarea
                className={`form-control ${errors.message ? "is-invalid" : ""}`}
                id="message"
                rows="6"
                placeholder={t("map.messagePlaceholder")}
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
              {errors.message && (
                <div className="invalid-feedback">{errors.message}</div>
              )}
            </div>

            <ButtonComponent
              label={t(loading ? "map.loadingButton" : "map.submitButton")}
              color="#ffffff"
              backgroundColor={loading ? "#999" : "#051435"}
              className="button__style"
              type="submit"
              disabled={loading} // Disable button when loading
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
