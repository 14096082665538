import React from "react";
import { Pagination } from "react-bootstrap";

const SharedPagination = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null;

  const visiblePageCount = 3;

  let start = Math.max(1, currentPage - Math.floor(visiblePageCount / 2));
  let end = start + visiblePageCount - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(1, end - visiblePageCount + 1);
  }

  const paginationRange = [...Array(end - start + 1).keys()].map(
    (n) => start + n
  );

  return (
    <div className="d-flex justify-content-center mt-4">
      <Pagination>
        <Pagination.First
          disabled={currentPage === 1}
          onClick={() => onPageChange(1)}
        />
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        />
        {paginationRange.map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        />
        <Pagination.Last
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(totalPages)}
        />
      </Pagination>
    </div>
  );
};

export default SharedPagination;
