import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  InputGroup,
  FormControl,
  Card,
  Row,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import "../../../styles/global.css";
import Logo from "../../../assets/mef_logo_black.png";
import { fetchAllObavijesti } from "../../../services/apiService";
import ButtonComponent from "../../../components/ButtonComponent";
import style from "./ObavijestiList.module.css";
import { useTranslation } from "react-i18next";
import SummaryComponent from "../../../components/SummaryComponent";
import SharedPagination from "../../../components/SharedPagination";

const firstFilterOptions = {
  bs: [
    "Sve obavijesti",
    "Javni konkursi",
    "Javne nabavke",
    "Obavijesti za studente",
    "Ostalo",
  ],
  en: [
    "All announcements",
    "Public competitions",
    "Public procurements",
    "Student notices",
    "Others",
  ],
};

const getFilterOptions = () => {
  const lang = localStorage.getItem("i18nextLng") || "bs";
  return firstFilterOptions[lang];
};

const filterTranslationMap = {
  en: {
    "All announcements": "Sve obavijesti",
    "Public competitions": "Javni konkursi",
    "Public procurements": "Javne nabavke",
    "Student notices": "Obavijesti za studente",
    Others: "Ostalo",
  },
  bs: {
    "Sve obavijesti": "Sve obavijesti",
    "Javni konkursi": "Javni konkursi",
    "Javne nabavke": "Javne nabavke",
    "Obavijesti za studente": "Obavijesti za studente",
    Ostalo: "Ostalo",
  },
};

const getBosnianFilter = (selectedFilter) => {
  const lang = localStorage.getItem("i18nextLng") || "bs";
  return filterTranslationMap[lang][selectedFilter];
};

const MyComponent = () => {
  const { t, i18n } = useTranslation();

  const [obavijesti, setObavijesti] = useState([]);
  const [obavijestiNum, setObavijestiNum] = useState(0);
  const [titleFilter, setTitleFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFirstFilter, setSelectedFirstFilter] = useState(() =>
    t("announcementSection.dropdownMenu.all")
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get("page") || 1, 10);
  const itemsPerPage = 6;
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchAllObavijesti(
        currentPage,
        titleFilter,
        getBosnianFilter(selectedFirstFilter)
      );
      setObavijestiNum(data.count);
      setObavijesti(data.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, titleFilter, selectedFirstFilter, i18n.language]);

  useEffect(() => {
    setSelectedFirstFilter(() => t("announcementSection.dropdownMenu.all"));
  }, [i18n.language]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setSearchParams({ page });
  };

  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);
    navigate("?page=1");
    setSearchParams({ page: 1 });
  };

  const handleFirstFilterChange = (filter) => {
    setSelectedFirstFilter(filter);
    setSearchParams({ page: 1 });
  };

  const totalPages = Math.ceil(obavijestiNum / itemsPerPage);

  return (
    <div className="py-5 bg-white">
      {/* Search Bar */}
      <Container>
        <div className="d-flex flex-wrap justify-content-between mb-4">
          <div className="col-12 col-lg-auto mb-2 mb-lg-0">
            <DropdownButton
              title={selectedFirstFilter}
              variant="white"
              onSelect={handleFirstFilterChange}
              className={`${style.dropDownStyle} w-100`}
            >
              {getFilterOptions().map((option) => (
                <Dropdown.Item key={option} eventKey={option} className="w-100">
                  {option}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="col-12 col-lg-auto mt-2 mt-lg-0">
            <InputGroup style={{ width: "100%" }}>
              <FormControl
                placeholder={t("announcementSection.searchLabel")}
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>
        </div>

        {/* Obavijesti Container */}
        <div className={style.announcementContainer}>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : obavijesti.length === 0 ? (
            <div className={style.noDataContainer}>
              <p>{t("announcementSection.noData")}</p>
            </div>
          ) : (
            obavijesti.map((item, idx) => (
              <Card key={idx} className={`${style.announcementCard} mb-3`}>
                <Link
                  to={
                    i18n.language === "en"
                      ? `/en/aktuelnosti/obavijesti/${item.slug}`
                      : `/aktuelnosti/obavijesti/${item.slug}`
                  }
                  state={{ fromPage: currentPage }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Row className="h-120px d-flex justify-content-between">
                    <img
                      src={Logo}
                      alt="Logo"
                      style={{ width: "120px", height: "90px" }}
                    />
                    <span className={style.announcementDate}>{item.date}</span>
                  </Row>

                  {/* Title and Summary */}
                  <div className="d-flex flex-column justify-content-between">
                    <div className="mb-2">
                      <div className="fs-5 fw-medium text-black mt-2">
                        {item.title}
                      </div>
                      <SummaryComponent text={item.summary} />
                    </div>
                    <ButtonComponent
                      label={t("announcementSection.readMore")}
                      color="#000"
                      backgroundColor="#9FDAF8"
                    />
                  </div>
                </Link>
              </Card>
            ))
          )}
        </div>

        <SharedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Container>
    </div>
  );
};

export default MyComponent;
