import React, { useState, useEffect } from "react";
import {
  getTeachingData,
  updateTeachingData,
} from "../../../services/userProfileService";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./TeachingData.css";

const rankOptions = [
  "Redovni profesor",
  "Vanredni profesor",
  "Docent",
  "Viši asistent",
  "Asistent",
];

const baseUrls = {
  linkedin: { name: "LinkedIn", link: "https://www.linkedin.com" },
  google_scholar: { name: "Google Scholar", link: "https://scholar.google.com" },
  orcid: { name: "ORCID", link: "https://orcid.org" },
  research_gate: { name: "ResearchGate", link: "https://www.researchgate.net" },
};

const TeachingDataForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    rank: "",
    phone: "",
    office: "",
    consultation: "",
    linkedin: baseUrls.linkedin.link,
    google_scholar: baseUrls.google_scholar.link,
    orcid: baseUrls.orcid.link,
    research_gate: baseUrls.research_gate.link,
    short_rank: "",
    first_name: "",
    last_name: "",
    slug: "",
    image: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Initially, fields are disabled

  const fetchTeachingData = async () => {
    try {
      const data = await getTeachingData();
      setFormData({
        rank: data.rank || "",
        phone: data.phone || "",
        office: data.office || "",
        consultation: data.consultation || "",
        linkedin: data.linkedin || baseUrls.linkedin.link,
        google_scholar: data.google_scholar || baseUrls.google_scholar.link,
        orcid: data.orcid || baseUrls.orcid.link,
        research_gate: data.research_gate || baseUrls.research_gate.link,
        short_rank: data.short_rank || "",
        first_name: data.user.first_name || "",
        last_name: data.user.last_name || "",
        slug: data.slug || "",
        image: data.user.image || "",
      });
    } catch (error) {
      toast.error("Greška pri dohvaćanju podataka.");
    }
  };

  useEffect(() => {
    fetchTeachingData();
  }, []);

  const validateField = (name, value) => {
    if (name === "phone" && value.trim() && !/^033\/\d{3}-\d{3}$/.test(value)) {
      return "Format telefona: 033/xxx-xxx";
    }
    if (name === "office" && value.trim() && !/^i?\d{3}$/.test(value)) {
      return "Dozvoljeni formati: npr. 106 za staru zgradu ili i106 za novu (istočnu) zgradu";
    }
    if (name === "consultation" && value.length > 75) {
      return "Konsultacije mogu imati maksimalno 75 znakova";
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    let error = validateField(name, value);

    if (name in baseUrls) {
      if (!value.startsWith(baseUrls[name].link)) {
        updatedValue = baseUrls[name].link;
      }
    }

    if (name === "rank") {
      updatedValue = value.toLowerCase();
    }

    setErrors((prev) => ({ ...prev, [name]: error }));
    setFormData((prev) => ({ ...prev, [name]: updatedValue }));
  };

  const isValidForm = () => {
    return (
      formData.rank.trim() !== "" &&
      formData.first_name.trim() !== "" &&
      formData.last_name.trim() !== "" &&
      Object.values(errors).every((error) => error === "")
    );
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await updateTeachingData(formData);
      toast.success("Podaci su uspješno ažurirani!");
      setIsEditing(false); // Disable fields after successful update
    } catch (error) {
      toast.error("Greška pri ažuriranju podataka.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="teaching-container bg-light">
      <h2 className="mb-4">Nastavnički podaci</h2>
      <Card className="shadow-sm p-4 rounded">
        <div className="d-flex align-items-center mb-4">
          <img src={formData.image} alt="Profile" className="profile-img" />
          <div className="ms-3">
            <h4 className="mb-0">
              {formData.slug ? (
                <span
                  className="name-link"
                  onClick={() =>
                    navigate(`/fakultet/nastavno-osoblje/${formData.slug}`)
                  }
                  title={`${window.location.origin}/fakultet/nastavno-osoblje/${formData.slug}`}
                >
                  {formData.short_rank} {formData.first_name} {formData.last_name}
                </span>
              ) : (
                `${formData.short_rank} ${formData.first_name} ${formData.last_name}`
              )}
            </h4>
          </div>
        </div>
        <hr />
        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Kabinet</Form.Label>
                <Form.Control
                  type="text"
                  name="office"
                  value={formData.office}
                  onChange={handleChange}
                  disabled={!isEditing}
                  isInvalid={!!errors.office}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.office}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  disabled={!isEditing}
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Konsultacije</Form.Label>
                <Form.Control
                  type="text"
                  name="consultation"
                  value={formData.consultation}
                  onChange={handleChange}
                  disabled={!isEditing}
                  isInvalid={!!errors.consultation}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.consultation}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Akademsko zvanje</Form.Label>
                <Form.Select
                  name="rank"
                  value={formData.rank}
                  onChange={handleChange}
                  disabled={!isEditing}
                >
                  {rankOptions.map((option) => (
                    <option key={option} value={option.toLowerCase()}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            {Object.keys(baseUrls).map((field) => (
              <Col md={6} key={field}>
                <Form.Group>
                  <Form.Label>{baseUrls[field].name} link</Form.Label>
                  <Form.Control
                    type="text"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    disabled={!isEditing}
                    isInvalid={!!errors[field]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[field]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ))}
          </Row>

          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="success"
              onClick={handleEdit}
              disabled={isEditing}
              className="me-2"
            >
              Izmijeni podatke
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={isLoading || !isEditing || !isValidForm()}
            >
              {isLoading ? "Sačuvaj promjene..." : "Sačuvaj promjene"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default TeachingDataForm;
