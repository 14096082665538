import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import DOMPurify from "dompurify";
import DocumentsSection from "../../../components/DocumentCard";
import "../../../styles/global.css";
import Gallery from "../../../components/Galerija";
import { fetchFondDetail } from "../../../services/apiService";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import HeadingComponent from "../../../components/HeadingComponent";
import style from "./FondoviDetaljno.module.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const FondDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [fondDetail, setFondDetail] = useState(null);
  const [error, setError] = useState(null);
  const bannerRef = useRef(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchFond = async (slug) => {
      try {
        const data = await fetchFondDetail(slug);
        setFondDetail(data);

        const updatedPath =
          i18n.language === "en"
            ? `/en/fakultet/fondovi/${data.slug}`
            : `/fakultet/fondovi/${data.slug}`;
        window.history.replaceState({}, "", updatedPath);
      } catch (error) {
        navigate("/not-found", { replace: true });
        setError("Failed to load news details. Please try again later.");
      }
    };

    fetchFond(slug);
  }, [i18n.language, navigate, slug]);

  if (error)
    return <p style={{ padding: "80px", textAlign: "center" }}>{error}</p>;

  if (!fondDetail)
    return <p style={{ padding: "80px", textAlign: "center" }}>Loading...</p>;

  // Prepare Navbar items based on available data
  const items = [
    { nameKey: "fond.content", link: "#sadrzaj" },
    ...(fondDetail.documents && fondDetail.documents.length > 0
      ? [{ nameKey: "fond.documents", link: "#dokumenti" }]
      : []),
    ...(fondDetail.gallery && fondDetail.gallery.length > 0
      ? [{ nameKey: "fond.gallery", link: "#galerija" }]
      : []),
  ];

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{fondDetail.meta_title}</title>
        <meta name="description" content={fondDetail.meta_description} />
                
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={fondDetail.title} />
        <meta property="og:description" content={t("meta.og.title")} />
        <meta property="og:image" content={fondDetail.image} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <HeadingComponent
        bannerRef={bannerRef}
        title={fondDetail.title}
        date={fondDetail.date}
        image={fondDetail.image}
        sectionName={t("fond.funds")}
        fallbackRoute={`${
          i18n.language === "en" ? "/en/" : "/"
        }fakultet/fondovi/`}
      />

      <SectionNavbar items={items} observeRef={bannerRef} />

      <div className="container__wrapper bg-light html__text">
        <Container id="sadrzaj">
          <div className="row align-items-center">
            <div className="col-md-8 order-1">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(fondDetail.content),
                }}
              />
            </div>

            <div className="col-md-4 text-center order-2 order-md-1">
              {fondDetail.logo && (
                <img
                  src={fondDetail.logo}
                  alt="fond slika"
                  className={`img-fluid rounded-3 ${style.logoImage}`}
                />
              )}
            </div>

          </div>
        </Container>
      </div>

      {fondDetail.documents && fondDetail.documents.length > 0 && (
        <div className="container__wrapper bg-white html__text">
          <div id="dokumenti">
            <DocumentsSection documents={fondDetail.documents} id="dokumenti" />
          </div>
        </div>
      )}

      {/* Gallery Section */}
      {fondDetail.gallery && fondDetail.gallery.length > 0 && (
        <Gallery images={fondDetail.gallery} />
      )}
    </div>
  );
};

export default FondDetail;
