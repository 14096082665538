import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaCity,
  FaGlobe,
  FaEnvelope,
  FaPhone,
  FaBuilding,
} from "react-icons/fa";

import { useTranslation } from "react-i18next";
import styles from "../../Pocetna/Kontakt.module.css";
import ContactCard from "../../../components/KontaktKartica";

const Kontakt = () => {
  const { t } = useTranslation();

  const contactData = [
    {
      title: t("contact.addressTitle"),
      details: [
        {
          icon: <FaMapMarkerAlt className="me-2" />,
          text: "Vilsonovo šetalište 9",
        },
        { icon: <FaCity className="me-2" />, text: "71000 Sarajevo" },
        { icon: <FaGlobe className="me-2" />, text: "Bosna i Hercegovina" },
      ],
    },
    {
      title: t("contact.contact"),
      details: [
        {
          icon: <FaEnvelope className="me-2" />,
          text: "masinac@mef.unsa.ba",
        },
        { icon: <FaPhone className="me-2" />, text: "+387 33 729 915" },
        { icon: <FaBuilding className="me-2" />, text: "i124" },
      ],
    },
  ];

  return (
    <Container>
      <Row>
        {contactData.map((data, index) => (
          <Col md={4} sm={12} className="mb-4" key={index}>
            <ContactCard
              title={data.title}
              details={data.details}
              customClass={styles.cardCustom}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Kontakt;
