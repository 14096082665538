import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import DOMPurify from "dompurify";
import DocumentsSection from "../../../components/DocumentCard";
import "../../../styles/global.css";
import Gallery from "../../../components/Galerija";
import { fetchNovostiDetail } from "../../../services/apiService";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import HeadingComponent from "../../../components/HeadingComponent";
import style from "./NovostiDetaljno.module.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const NewsDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [newsDetail, setNewsDetail] = useState(null);
  const [error, setError] = useState(null);
  const bannerRef = useRef(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchNewsDetail = async (slug) => {
      try {
        const data = await fetchNovostiDetail(slug);
        setNewsDetail(data);

        const updatedPath =
          i18n.language === "en"
            ? `/en/aktuelnosti/novosti/${data.slug}`
            : `/aktuelnosti/novosti/${data.slug}`;
        window.history.replaceState({}, "", updatedPath);
      } catch (error) {
        navigate("/not-found", { replace: true });
        setError("Failed to load news details. Please try again later.");
      }
    };

    fetchNewsDetail(slug);
  }, [i18n.language, navigate, slug]);

  if (error)
    return <p style={{ padding: "80px", textAlign: "center" }}>{error}</p>;

  if (!newsDetail)
    return <p style={{ padding: "80px", textAlign: "center" }}>Loading...</p>;

  // Prepare Navbar items based on available data
  const items = [
    { nameKey: "navbarVijest.content", link: "#sadrzaj" },
    ...(newsDetail.documents && newsDetail.documents.length > 0
      ? [{ nameKey: "navbarVijest.documents", link: "#dokumenti" }]
      : []),
    ...(newsDetail.gallery && newsDetail.gallery.length > 0
      ? [{ nameKey: "navbarVijest.gallery", link: "#galerija" }]
      : []),
  ];

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{newsDetail.meta_title}</title>
        <meta name="description" content={newsDetail.meta_description} />
                
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={newsDetail.title} />
        <meta property="og:description" content={t("meta.og.title")} />
        <meta property="og:image" content={newsDetail.image} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <HeadingComponent
        bannerRef={bannerRef}
        title={newsDetail.title}
        date={newsDetail.date}
        image={newsDetail.image}
        sectionName={t("novostiDetaljno.announcements")}
        fallbackRoute={`${
          i18n.language === "en" ? "/en/" : "/"
        }aktuelnosti/novosti/`}
      />

      <SectionNavbar items={items} observeRef={bannerRef} />

      {/* News Content Section */}
      <div className="container__wrapper bg-white html__text">
        <Container id="sadrzaj">
          <Card className={`${style.cardWrapper} mb-4`}>
            <Card.Body>
              <Card.Text className="fs-6 fw-semibold text-dark">
                {newsDetail.summary}
              </Card.Text>
            </Card.Body>
          </Card>

          <div className={style.newsContent}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(newsDetail.content),
              }}
            />
          </div>
          {newsDetail.documents && newsDetail.documents.length > 0 && (
            <div id="dokumenti">
              <DocumentsSection
                documents={newsDetail.documents}
                id="dokumenti"
              />
            </div>
          )}
        </Container>
      </div>

      {/* Gallery Section */}
      {newsDetail.gallery && newsDetail.gallery.length > 0 && (
        <Gallery images={newsDetail.gallery} />
      )}
    </div>
  );
};

export default NewsDetail;
