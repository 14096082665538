import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form, InputGroup, Toast } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthContext } from "../../contexts/AuthContext";
import { loginUser } from "../../services/authService";
import "./LoginModal.css";
import { useTranslation } from "react-i18next";

const LoginModal = ({ showModal, handleClose }) => {
  const { login } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ usernameOrEmail: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    usernameOrEmail: "",
    password: "",
  });
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const isDisabled = !formData.usernameOrEmail || !formData.password;

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (value.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        [name]: t("loginPage.requiredField"),
      }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }

    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let hasError = false;
    if (!formData.usernameOrEmail) {
      errors.usernameOrEmail = t("loginPage.requiredField");
      hasError = true;
    }
    if (!formData.password) {
      errors.password = t("loginPage.requiredField");
      hasError = true;
    }

    if (hasError) {
      setIsSubmitting(false);
      return;
    }

    try {
      const data = await loginUser(formData.usernameOrEmail, formData.password);
      login(data);
      handleClose();
    } catch (error) {
      if (error.message_bs || error.message_en) {
        const language = localStorage.getItem("i18nextLng") || "bs";
        const errorMsg =
          language === "bs" ? error.message_bs[0] : error.message_en[0];
        const adminEmail = error.admin_mail?.[0];

        setErrorMessage(
          adminEmail
            ? `${errorMsg} ${
                language === "bs" ? "Kontaktirajte: " : "Contact: "
              }${adminEmail}`
            : errorMsg
        );
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }

      setIsSubmitting(false); // Re-enable the button if submission fails
    }
  };

  const resetForm = () => {
    setFormData({ usernameOrEmail: "", password: "" });
    setErrors({ usernameOrEmail: "", password: "" });
    setErrorMessage("");

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!showModal) {
      resetForm();
    }
  }, [showModal]);

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header
        closeButton
        className="modal-header-banner"
        closeVariant="white"
      >
        <Modal.Title>{t("loginPage.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="usernameOrEmail" className="mb-3">
            <Form.Control
              type="text"
              placeholder={t("loginPage.username")}
              name="usernameOrEmail"
              value={formData.usernameOrEmail}
              onChange={handleChange}
              isInvalid={!!errors.usernameOrEmail}
            />
            <Form.Control.Feedback type="invalid">
              {errors.usernameOrEmail}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password" className="mb-3">
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder={t("loginPage.password")}
                name="password"
                value={formData.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
              <InputGroup.Text
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          {errorMessage && (
            <div className="error-message" style={{ color: "red" }}>
              {errorMessage}
            </div>
          )}
          <Button
            variant="primary"
            type="submit"
            block
            className="mt-3"
            disabled={isDisabled || isSubmitting} // Disable the button when submitting
          >
            {isSubmitting ? t("loginPage.loginWaiting") : t("loginPage.login")}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
