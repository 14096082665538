import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import style from "./PocetnaBaner.module.css";
import backgroundVideoDesktop from "../../assets/video/MEF_video_destkop.mp4"; // Import the video file
import backgroundVideoMobile from "../../assets/video/MEF_video_mobile.mp4";
import { useTranslation } from "react-i18next";

const WelcomeBanner = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const { t } = useTranslation();

  // Update view on window resize
  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div class={style.bannerStyle}>
      {window.navigator.connection &&
      window.navigator.connection.effectiveType === "3g" ? null : (
        <video class={style.videoStyle} autoPlay loop muted playsInline>
          <source
            src={isDesktop ? backgroundVideoDesktop : backgroundVideoMobile}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="container" style={{ zIndex: 1 }}>
        <h1 className="heading-subtitle">{t("welcome.heading")}</h1>
        <p className="heading-1">{t("welcome.subheading")}</p>
      </div>
    </div>
  );
};

export default WelcomeBanner;
