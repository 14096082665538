import React from "react";
import MainBanner from "../../../components/Baner";
import ObavijestiList from "./ObavijestiList";
import { useTranslation } from "react-i18next";
import obavijestiBanner from "../../../assets/obavijesti.jpg";
import { Helmet } from "react-helmet-async";


const ObavijestiComponent = () => {
  const { t } = useTranslation();

  return (
    <>
    <Helmet>
        {/* Meta Tags */}
        <title>{t("announcementSection.metaTitle")}</title>
        <meta name="description" content={ t("announcementSection.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("announcementSection.metaTitle")}/>
        <meta property="og:description" content={t("announcementSection.ogMetaDescription")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        title={t("obavijestiDetaljno.bannerTitle")}
        bannerImage={obavijestiBanner}
        description={t("obavijestiDetaljno.bannerDescription")}
      />
      <ObavijestiList />
    </>
  );
};

export default ObavijestiComponent;
