import React from "react";
import { Row, Col, Card} from "react-bootstrap";
import "../../styles/global.css";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import firstImage from "../../assets/saradnje/image-01.png";
import secondImage from "../../assets/saradnje/image-02.png";
import thirdImage from "../../assets/saradnje/image-03.png";
import fourthImage from "../../assets/saradnje/image-04.png";
import { useTranslation } from "react-i18next";

const projectsDummy = [
  {
    id: 1,
    titleKey: "projectSection.project1",
    descriptionKey: "projectSection.description1",
    image: firstImage,
  },
  {
    id: 2,
    titleKey: "projectSection.project2",
    descriptionKey: "projectSection.description2",
    image: secondImage,
  },
  {
    id: 3,
    titleKey: "projectSection.project3",
    descriptionKey: "projectSection.description3",
    image: thirdImage,
  },
  {
    id: 4,
    titleKey: "projectSection.project4",
    descriptionKey: "projectSection.description4",
    image: fourthImage,
  },
];

const ProjektiSaradnja = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white container__wrapper" id="projekti">
      <div className="container">
        {/* Title and Description */}
        <Row className="align-items-center mb-3">
          <Col xs={12} md={8}>
            <h1 style={{ fontSize: "40px", fontWeight: "500" }}>
              {t("projectSection.title")}
            </h1>
            <p className="section_subtitle">{t("projectSection.subtitle")}</p>
          </Col>
          <Col xs={12} md={4} className="text-md-end mt-4 mt-md-0">
            <Link
              to={`/fakultet/projekti`}
              style={{ textDecoration: "none", color: "inherit" }}
              className="section__buttons"
            >
              <ButtonComponent
                label={t("projectSection.moreProjects")}
                color="#ffffff"
                backgroundColor="#051435"
              />
            </Link>
          </Col>
        </Row>

        {/* 4 Cards */}
        <Row className="my-2">
          {projectsDummy.map((card, index) => (
            <Col
              xs={12}
              sm={6}
              md={3}
              key={index}
              className="mb-4 d-flex justify-content-center"
            >
              <Card
                style={{
                  width: "100%",
                  height: "292px",
                  border: "1px solid #DDDDDD",
                }}
              >
                <Card.Body
                  style={{ backgroundColor: "white", borderRadius: "12px" }}
                >
                  <div className="d-flex flex-column justify-content-between h-100">
                    <img
                      src={card.image}
                      alt="Logo"
                      style={{ width: "150px", height: "50px" }}
                    />
                    <p>{t(card.descriptionKey)}</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Latest News Section
        <Row className="my-5">
          <Col xs={12} className="mb-3">
            <div
              className="d-flex align-items-center pb-3 mb-3"
              style={{ borderBottom: "1px solid #DDDDDD" }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: "#9FDAF8",
                  borderRadius: "50%",
                  marginRight: "20px",
                }}
              ></div>
              <h2
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Najnoviji projekti
              </h2>
            </div>
          </Col>

          <Col
            xs={12}
            sm={8}
            md={6}
            lg={4}
            className="d-flex justify-content-start"
          >
            <Card
              style={{
                width: "100%",
                maxWidth: "400px",
                height: "330px",
                borderRadius: "12px",
                border: "1px solid #DDDDDD",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "160px",
                  overflow: "hidden",
                }}
              >
                {/* Replace with actual image */}
        {/* <img
                  src={fifthImage}
                  alt="News"
                  style={{ width: "100%", height: "100%" }}
                /> */}
        {/* <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    width: "80px",
                    height: "35px",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  12.12.2022
                </div>
              </div>
              <Card.Body>
                <h5 className="mb-3">Random News Title</h5>
                <p className="mb-3">Random sentence describing the news.</p> */}

        {/* <ButtonComponent
                  color={"white"}
                  backgroundColor={"#051435"}
                  label={
                    localStorage.getItem("language") === "bs"
                      ? "Pročitaj više →"
                      : "Read more →"
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default ProjektiSaradnja;
