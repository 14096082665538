import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./AnimacijaLinije.css";
import style from "./PlaviBaner.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchStatistics } from "../services/apiService";

const BlueBannerComponent = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStatistics();
        setStats(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching stats data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth <= 768 ? 1 : 3,
    slidesToScroll: window.innerWidth <= 768 ? 1 : 3,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`container ${style.bannerStyle} mt-4`}>
      <Slider {...settings}>
        {stats
          .map((item, index) => (
            <div key={index} className="px-3">
              <div className={style.statItemStyle}>
                <div className={style.statNumberStyle}>{item.value}</div>
                <div className={style.statTextStyle}>{item.header}</div>
              </div>
            </div>
          ))
          .reduce((acc, current, idx) => {
            if (window.innerWidth <= 768) {
              if (idx % 3 === 0) {
                acc.push(
                  <div key={`group-${idx}`} className={style.mobileGroupStyle}>
                    {current}
                    {stats[idx + 1] && (
                      <div
                        key={`item-${idx + 1}`}
                        className={style.statItemStyle}
                      >
                        <div className={style.statNumberStyle}>
                          {stats[idx + 1].value}
                        </div>
                        <div className={style.statTextStyle}>
                          {stats[idx + 1].header}
                        </div>
                      </div>
                    )}
                    {stats[idx + 2] && (
                      <div
                        key={`item-${idx + 2}`}
                        className={style.statItemStyle}
                      >
                        <div className={style.statNumberStyle}>
                          {stats[idx + 2].value}
                        </div>
                        <div className={style.statTextStyle}>
                          {stats[idx + 2].header}
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            } else {
              acc.push(current);
            }
            return acc;
          }, [])}
      </Slider>

      <div className="line" style={{ left: "20%", animationDelay: "0s" }}></div>
      <div className="line" style={{ left: "50%", animationDelay: "1s" }}></div>
      <div className="line" style={{ left: "80%", animationDelay: "2s" }}></div>
    </div>
  );
};

export default BlueBannerComponent;
