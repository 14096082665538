import React, { useRef, useState } from "react";
import MainBanner from "../../../components/Baner";
import { useTranslation } from "react-i18next";
import KonferencijeBanner from "../../../assets/mef-slike/konferencije.jpg";
import KonferencijeListComponent from "./KonferencijeLista";
import { Helmet } from "react-helmet-async";

const KonferencijeComponent = () => {
  const [masinijade, setMasinijade] = useState([]);
  const bannerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const items = [
    { nameKey: "masinijada.about", link: "#omasinijadi" },
    { nameKey: "masinijada.previous", link: "#prethodne" },
  ];

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("konferencije.metaTitle")}</title>
        <meta name="description" content={ t("konferencije.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("konferencije.metaTitle")}/>
        <meta property="og:description" content={t("konferencije.ogMetaDescription")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>
      
      <MainBanner
        bannerRef={bannerRef}
        title={t("konferencije.title")}
        styleImage={true}
        bannerImage={KonferencijeBanner}
        description={t("konferencije.description")}
      />

      <div className="container__wrapper bg-white" id="prethodne">
        <KonferencijeListComponent />
      </div>
    </div>
  );
};

export default KonferencijeComponent;
