import React, { useState } from "react";
import { changeUserPassword } from "../../../services/userProfileService";
import { Button, Form, Row, Col, Card, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const validateForm = (updatedFormData) => {
    let newErrors = {};

    if (!updatedFormData.old_password.trim()) {
      newErrors.old_password = "Polje ne može biti prazno.";
    }

    if (!updatedFormData.new_password.trim()) {
      newErrors.new_password = "Polje ne može biti prazno.";
    } else if (updatedFormData.new_password.length < 8) {
      newErrors.new_password = "Lozinka mora sadržavati najmanje 8 karaktera.";
    }

    if (!updatedFormData.confirm_password.trim()) {
      newErrors.confirm_password = "Polje ne može biti prazno.";
    } else if (
      updatedFormData.confirm_password !== updatedFormData.new_password
    ) {
      newErrors.confirm_password = "Lozinke se ne podudaraju.";
    }

    setErrors(newErrors);
    setIsValid(Object.keys(newErrors).length === 0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await changeUserPassword(formData);
      toast.success("Lozinka uspješno promijenjena!");

      setFormData({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
      setErrors({});
      setIsValid(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (errorData.non_field_errors) {
          toast.error(
            errorData.non_field_errors[0] || "Greška prilikom promjene lozinke."
          );
        } else {
          toast.error("Greška prilikom promjene lozinke.");
        }
      }
    }
  };

  return (
    <div className="profile-container bg-light">
      <h2 className="mb-4">Promjena lozinke</h2>
      <Card className="shadow-sm p-4 rounded">
        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col md={7}>
              <Form.Group>
                <Form.Label>Stara lozinka</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordVisible.old_password ? "text" : "password"}
                    name="old_password"
                    value={formData.old_password}
                    onChange={handleChange}
                    isInvalid={!!errors.old_password}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => togglePasswordVisibility("old_password")}
                  >
                    {passwordVisible.old_password ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {errors.old_password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={7}>
              <Form.Group>
                <Form.Label>Nova lozinka</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordVisible.new_password ? "text" : "password"}
                    name="new_password"
                    value={formData.new_password}
                    onChange={handleChange}
                    isInvalid={!!errors.new_password}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => togglePasswordVisibility("new_password")}
                  >
                    {passwordVisible.new_password ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {errors.new_password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={7}>
              <Form.Group>
                <Form.Label>Potvrda lozinke</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={
                      passwordVisible.confirm_password ? "text" : "password"
                    }
                    name="confirm_password"
                    value={formData.confirm_password}
                    onChange={handleChange}
                    isInvalid={!!errors.confirm_password}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => togglePasswordVisibility("confirm_password")}
                  >
                    {passwordVisible.confirm_password ? (
                      <FaEyeSlash />
                    ) : (
                      <FaEye />
                    )}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {errors.confirm_password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-end mt-4">
            <Button
              type="submit"
              variant="primary"
              className="btn-save"
              disabled={isLoading || !isValid}
            >
              {isLoading ? "Mijenjanje..." : "Promijeni lozinku"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePassword;
