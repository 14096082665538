import React, { useRef } from "react";
import { Helmet } from "react-helmet-async";
import MainBanner from "../../../components/Baner";
import historijaBanner from "../../../assets/mef-slike/historija.jpg";
import MasinskiFakultet from "../../../assets/mef-slike/mef.jpg";
import { useTranslation } from "react-i18next";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import TimeLine from "./TimeLine";
import style from "./Historija.module.css"


const HistorijaComponent = () => {
  const bannerRef = useRef(null);
  const { t } = useTranslation();
  const items = [
    { nameKey: "historija.about", link: "#ohistoriji" },
    { nameKey: "historija.timeline", link: "#timeline" },
  ];

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("historija.metaTitle")}</title>
        <meta name="description" content={ t("historija.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("historija.metaTitle")}/>
        <meta property="og:description" content={t("historija.description")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        bannerRef={bannerRef}
        title={t("historija.title")}
        styleImage={true}
        bannerImage={historijaBanner}
        description={t("historija.description")}
      />
      <SectionNavbar items={items} observeRef={bannerRef} />

      <section className="bg-light container__wrapper" id="ohistoriji">
        <div className="container d-flex flex-wrap justify-content-between align-items-center html__text">
          <div className="col-md-7">
            <p className="card_subtitle">{t("historija.content1")}</p>
            <p className="card_subtitle">{t("historija.content2")}</p>
          </div>
          <div className="col-lg-4">
            <img className={style.imageStyle} src={MasinskiFakultet} alt={"Masinijada slika"} />
          </div>
        </div>
      </section>
      <div className="container__wrapper bg-white" id="timeline">
        <TimeLine />
      </div>
    </div>
  );
};

export default HistorijaComponent;
