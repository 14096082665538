import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Use this if using react-router-dom
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import ButtonComponent from "../../components/ButtonComponent";
import { fetchOdsjeci } from "../../services/apiService";
import styles from "./Odsjeci.module.css";
import { useTranslation } from "react-i18next";

const FacultyDepartments = () => {
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchOdsjeci(); // Fetch data using the service function
        setDepartments(data);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching news:", error);
      }
    };

    loadData(); // Call the function to load the data
  }, [i18n.language]);

  const handleCardClick = (slug) => {
    // Navigate to the department detail page
    navigate(`/odsjeci/${slug}`);
  };

  return (
    <div className="bg-light container__wrapper">
      <div className="container">
        <div className="row g-4">
          {departments.map((dept) => (
            <div
              key={dept.slug}
              className="col-lg-4 col-md-6 col-12"
              onClick={() => handleCardClick(dept.slug)}
              style={{ cursor: "pointer" }}
            >
              <div class={`${styles.departmentsCard} card d-flex flex-column`}>
                <div style={{ fontSize: "24px", marginBottom: "10px" }}>
                  <img
                    src={dept.icon}
                    alt={`${dept.name} icon`}
                    width="30px"
                    height="30px"
                  />
                </div>

                <h4 className="card-title" style={{ marginBottom: "auto" }}>
                  {dept.name}
                </h4>

                <ButtonComponent
                  label={t("facultyDepartments.readMore")}
                  color="#000"
                  backgroundColor="#9FDAF8"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering card click
                    handleCardClick(dept.slug); // Handle button click
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FacultyDepartments;
