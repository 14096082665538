import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import {
  FaUpload,
  FaCalendarAlt,
  FaChevronDown,
  FaTrashAlt,
} from "react-icons/fa";
import {
  addExamResult,
  getSubjectList,
  getTermList,
  updateExamResult,
} from "../../../services/userProfileService";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AddExam.css";

const AddExam = ({ onCancel, existingData, onExamUpdate }) => {
  const [subjects, setSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [loadingSubjects, setLoadingSubjects] = useState(false);

  const [terms, setTerms] = useState([]);
  const [filteredTerms, setFilteredTerms] = useState([]);
  const [loadingTerms, setLoadingTerms] = useState(false);

  const [showSubjectDropdown, setShowSubjectDropdown] = useState(false);
  const [searchSubject, setSearchSubject] = useState("");
  const [showTermDropdown, setShowTermDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");

  // const [isDragging, setIsDragging] = useState(false);

  const [formData, setFormData] = useState({
    subject: "",
    subjectName: "",
    term: "",
    termName: "",
    exam_date: null,
    file: null,
    existingFile: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAllSubjects();
    fetchAllTerms();
  }, []);

  useEffect(() => {
    if (existingData) {
      setSelectedSubject(existingData.subjectName || "");
      setSelectedTerm(existingData.termName || "");

      setFormData({
        subject: existingData.subject || "",
        subjectName: existingData.subjectName || "",
        term: existingData.term || "",
        termName: existingData.termName || "",
        exam_date: existingData.exam_date
          ? new Date(existingData.exam_date)
          : null,
        file: null,
        existingFile: existingData.file || null,
      });
    }
  }, [existingData]);

  const fetchAllSubjects = async () => {
    try {
      const data = await getSubjectList("");
      setSubjects(data);
      setFilteredSubjects(data);

      if (existingData?.subject) {
        const match = data.find((s) => s.id === existingData.subject);
        if (match) {
          setSelectedSubject(match.name);
          setFormData((prev) => ({
            ...prev,
            subject: match.id,
          }));
        }
      }
    } catch (error) {
      toast.error("Greška pri učitavanju predmeta.");
    }
  };

  const fetchAllTerms = async () => {
    try {
      const data = await getTermList("");
      setTerms(data);
      setFilteredTerms(data);

      if (existingData?.term) {
        const match = data.find((t) => t.id === existingData.term);
        if (match) {
          setSelectedTerm(match.term);
          setFormData((prev) => ({
            ...prev,
            term: match.id,
          }));
        }
      }
    } catch (error) {
      toast.error("Greška pri učitavanju rokova.");
    }
  };

  const handleSubjectSearch = (e) => {
    const value = e.target.value;
    setSearchSubject(value);

    if (!value) {
      setFilteredSubjects(subjects);
    } else {
      const filtered = subjects.filter((sub) =>
        sub.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSubjects(filtered);
    }
  };

  const handleTermSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (!value) {
      setFilteredTerms(terms);
    } else {
      const filtered = terms.filter((t) =>
        t.term.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTerms(filtered);
    }
  };

  const handleSubjectSelect = (sub) => {
    setSelectedSubject(sub.name);
    setFormData((prev) => ({
      ...prev,
      subject: sub.id,
    }));
    setShowSubjectDropdown(false);
  };

  const handleTermSelect = (t) => {
    setSelectedTerm(t.term);
    setFormData((prev) => ({
      ...prev,
      term: t.id,
    }));
    setShowTermDropdown(false);
  };

  const toggleSubjectDropdown = (isOpen) => {
    setShowSubjectDropdown(isOpen);
    if (isOpen) {
      setSearchSubject("");
      setFilteredSubjects(subjects);
    }
  };

  const toggleTermDropdown = (isOpen) => {
    setShowTermDropdown(isOpen);
    if (isOpen) {
      setSearchTerm("");
      setFilteredTerms(terms);
    }
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, exam_date: date }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
      ];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Dozvoljeni formati su PDF, Word ili Excel.");
        return;
      }
      setFormData((prev) => ({ ...prev, file }));
    }
  };

  const handleRemoveFile = () => {
    setFormData((prev) => ({ ...prev, file: null, existingFile: null }));
  };

  const isFormValid = () => {
    return (
      formData.subject &&
      formData.term &&
      formData.exam_date &&
      (formData.file || formData.existingFile)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;

    const submissionData = new FormData();
    submissionData.append("subject", formData.subject);
    submissionData.append("term", formData.term);
    submissionData.append(
      "exam_date",
      formData.exam_date.toISOString().split("T")[0]
    );
    if (formData.file) {
      submissionData.append("file", formData.file);
    }

    try {
      setIsLoading(true);
      if (existingData) {
        await updateExamResult(existingData.id, submissionData);
        toast.success("Rezultat ispita uspješno izmijenjen!");
      } else {
        await addExamResult(submissionData);
        toast.success("Rezultat ispita uspješno dodat!");
      }
      onExamUpdate();
      onCancel();
    } catch (error) {
      toast.error("Greška pri dodavanju rezultata.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    // setIsDragging(true);
  };

  const handleDragLeave = () => {
    // setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    if (file) {
      const mockEvent = { target: { files: [file] } };
      handleFileChange(mockEvent);
    }
  };

  return (
    <div className="dodaj-rezultat-container p-4">
      <h2 className="mb-3">
        {existingData ? "Uredi rezultat ispita" : "Dodaj nove rezultate ispita"}
      </h2>
      <Card className="p-4 shadow-sm">
        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Predmet</Form.Label>
                <Dropdown
                  show={showSubjectDropdown}
                  onToggle={toggleSubjectDropdown}
                  autoClose="outside"
                >
                  <Dropdown.Toggle as={InputGroup}>
                    <FormControl
                      placeholder="Odaberi predmet"
                      readOnly
                      value={selectedSubject}
                    />
                    <InputGroup.Text>
                      <FaChevronDown />
                    </InputGroup.Text>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    <FormControl
                      placeholder="Pretraži predmet..."
                      value={searchSubject}
                      onChange={handleSubjectSearch}
                      onClick={(e) => e.stopPropagation()}
                      className="dropdown-search-input"
                    />
                    {loadingSubjects ? (
                      <Dropdown.Item as="div" disabled>
                        Učitavanje...
                      </Dropdown.Item>
                    ) : filteredSubjects.length === 0 ? (
                      <Dropdown.Item as="div" disabled>
                        Nema rezultata
                      </Dropdown.Item>
                    ) : (
                      filteredSubjects.map((sub) => (
                        <Dropdown.Item
                          key={sub.id}
                          onClick={() => handleSubjectSelect(sub)}
                        >
                          {sub.name}
                        </Dropdown.Item>
                      ))
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Rok</Form.Label>
                <Dropdown
                  show={showTermDropdown}
                  onToggle={toggleTermDropdown}
                  autoClose="outside"
                >
                  <Dropdown.Toggle as={InputGroup}>
                    <FormControl
                      placeholder="Odaberi rok"
                      readOnly
                      value={selectedTerm}
                    />
                    <InputGroup.Text>
                      <FaChevronDown />
                    </InputGroup.Text>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    <FormControl
                      placeholder="Pretraži rok..."
                      value={searchTerm}
                      onChange={handleTermSearch}
                      onClick={(e) => e.stopPropagation()}
                      className="dropdown-search-input"
                    />
                    {loadingTerms ? (
                      <Dropdown.Item as="div" disabled>
                        Učitavanje...
                      </Dropdown.Item>
                    ) : filteredTerms.length === 0 ? (
                      <Dropdown.Item as="div" disabled>
                        Nema rezultata
                      </Dropdown.Item>
                    ) : (
                      filteredTerms.map((t) => (
                        <Dropdown.Item
                          key={t.id}
                          onClick={() => handleTermSelect(t)}
                        >
                          {t.term}
                        </Dropdown.Item>
                      ))
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
          </Row>

          <Row className="g-3 mt-3">
            <Col md={6}>
              <Form.Group className="w-100">
                <Form.Label>Datum ispita</Form.Label>
                <InputGroup className="w-100">
                  <DatePicker
                    selected={formData.exam_date}
                    onChange={handleDateChange}
                    className="form-control w-100"
                    placeholderText="Odaberite datum ispita"
                    dateFormat="dd.MM.yyyy"
                    maxDate={new Date()}
                  />
                  <InputGroup.Text>
                    <FaCalendarAlt />
                  </InputGroup.Text>
                </InputGroup>

              </Form.Group>
            </Col>
            {existingData && existingData.result_date && (
              <Col md={6}>
                <Form.Group className="w-100">
                  <Form.Label>Datum objave*</Form.Label>
                  <InputGroup className="w-100">
                    <DatePicker
                      selected={existingData.result_date}
                      className="form-control w-100"
                      dateFormat="dd.MM.yyyy"
                      disabled
                    />
                    <InputGroup.Text>
                      <FaCalendarAlt />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            )}
              <small className="text-muted mt-2 d-block fst-italic">
                *Datum objave će biti dan kada su rezultati dodani, u slučaju
                da je potrebno izmijeniti datum obratite se{" "}
                <a
                  href="mailto:programiranje.mfs@gmail.com"
                  className="text-primary"
                >
                  administratoru
                </a>.
              </small>
          </Row>

          <Row className="g-3 mt-4">
            <Col md={12}>
              <Form.Group className="file-upload-box text-center bg-light p-4">
                {formData.existingFile ? (
                  <>
                    <p>
                      📄 Trenutni fajl:{" "}
                      <a
                        href={formData.existingFile}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {existingData.file.split("/").pop()}
                      </a>
                    </p>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={handleRemoveFile}
                    >
                      <FaTrashAlt /> Obriši fajl
                    </Button>
                  </>
                ) : (
                  <div
                    className="file-upload-box"
                    onClick={() =>
                      document.getElementById("file-upload").click()
                    }
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <FaUpload size={24} />
                    <p>Prenesite fajl sa računara</p>
                    <small>
                      Fajl treba da bude u PDF, Word ili Excel formatu.
                    </small>

                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleFileChange}
                      className="d-none"
                    />

                    {formData.file && (
                      <p className="mt-2">
                        📄 <strong>{formData.file.name}</strong>
                      </p>
                    )}
                  </div>
                )}
              </Form.Group>
            </Col>
            <small className="text-muted mt-3 d-block fst-italic">
                *Dokument rezultata ispita bi trebao posjedovati samo brojeve indeksa studenata.
              </small>
          </Row>

          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={onCancel}>
              Otkaži
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isFormValid() || isLoading}
            >
              {isLoading
                ? "Obrada..."
                : existingData
                ? "Izmjeni rezultate"
                : "Dodaj rezultate"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default AddExam;
