import { useState, useEffect } from "react";
import { truncateText } from "../helpers/utils"; // Adjust path as needed

function SummaryComponent({ text  }) {
  const [maxLength, setMaxLength] = useState(window.innerWidth < 1000 ? 150 : 500);

  useEffect(() => {
    const handleResize = () => {
      setMaxLength(window.innerWidth < 1000 ? 150 : 500);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="fs-6 fw-normal text-black mt-3">
      {truncateText(text, maxLength)}
    </div>
  );
}

export default SummaryComponent;
