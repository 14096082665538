import React, { useState, useEffect } from "react";
import {
  FaArrowLeft,
  FaEnvelope,
  FaPhone,
  FaBuilding,
  FaLinkedin,
  FaGoogle,
  FaResearchgate,
  FaOrcid,
} from "react-icons/fa";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import style from "./NastavnoOsobljeDetail.module.css";
import { useTranslation } from "react-i18next";
import { fetchProfesoriDetail } from "../../../services/apiService";
import { Helmet } from "react-helmet-async";

const ProfessorDetail = () => {
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [professor, setProfessor] = useState(null);

  useEffect(() => {
    const fetchProfessor = async () => {
      try {
        const data = await fetchProfesoriDetail(slug);
        setProfessor(data);
      } catch (error) {
        navigate("/not-found", { replace: true });
        console.error("Error fetching professor:", error);
      }
    };

    fetchProfessor();
  }, [i18n.language]);

  if (!professor) {
    return <div>{t("loading")}</div>;
  }

  // Generate dynamic meta tags
  const pageTitle = t("professorDetail.metaTitle", {
    rank: professor.short_rank,
    firstName: professor.user.first_name,
    lastName: professor.user.last_name,
  });

  const handleBackNavigation = () => {
    try {
      navigate(-1);
    } catch (error) {
      const fallbackRoute =
        i18n.language === "en"
          ? "/en/fakultet/nastavno-osoblje/"
          : "/fakultet/nastavno-osoblje/";
      navigate(fallbackRoute);
    }
  };

  return (
    <>
      {/* Helmet for meta tags */}
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={t("professorDetail.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={t("professorDetail.ogMetaDescription")} />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <div className="bg-white container__wrapper" style={{ marginTop: "50px" }}>
        <Container>
          {/* Back button */}
          <div onClick={handleBackNavigation} className={style.backButton}>
            <FaArrowLeft className="me-2" /> {t("professorDetail.back")}
          </div>

          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            {/* Professor Information Section */}
            <Col xs={12} md={4} lg={4} className={style.contentWrapper} style={{ marginBottom: "1.5rem" }}>
              <div className="d-flex mb-3">
                <img
                  className="professor-image avatar me-3"
                  src={professor.user.image}
                  alt={`${professor.user.first_name} ${professor.user.last_name}`}
                />
                <div>
                  <p style={{ fontSize: "18px", fontWeight: "500", margin: "0" }}>
                    {professor.short_rank} {professor.user.first_name} {professor.user.last_name}
                  </p>
                  <p className="text-muted" style={{ fontSize: "12px", marginBottom: "0" }}>
                    {professor.full_rank}
                  </p>
                </div>
              </div>

              <hr style={{ borderColor: "#DDDDDD" }} />

              {/* Contact Information */}
              <div className="mt-3">
                <div className="mb-2 d-flex align-items-center">
                  <OverlayTrigger placement="top" overlay={<Tooltip>{t("professorDetail.email")}</Tooltip>}>
                    <div target="_blank" style={{ display: "inline-block" }}>
                      <FaEnvelope className="me-2" />
                    </div>
                  </OverlayTrigger>
                  <a href={`mailto:${professor.user.email}`} style={{ color: "black" }}>
                    {professor.user.email}
                  </a>
                </div>

                <div className="mb-2 d-flex align-items-center">
                  <OverlayTrigger placement="top" overlay={<Tooltip>{t("professorDetail.phone")}</Tooltip>}>
                    <div target="_blank" style={{ display: "inline-block" }}>
                      <FaPhone className="me-2" />
                    </div>
                  </OverlayTrigger>
                  {professor.phone || t("professorDetail.notAvailable")}
                </div>

                <div className="mb-2 d-flex align-items-center">
                  <OverlayTrigger placement="top" overlay={<Tooltip>{t("professorDetail.office")}</Tooltip>}>
                    <div target="_blank" style={{ display: "inline-block" }}>
                      <FaBuilding className="me-2" />
                    </div>
                  </OverlayTrigger>
                  {professor.office || t("professorDetail.notAvailable")}
                </div>
              </div>

              <hr className={style.borderBottom} />

              {/* Consultation and Social Links */}
              <div>
                <div className="mb-2">
                  <strong>{t("professorDetail.consultation")}</strong> {professor.consultation || t("professorDetail.notAvailable")}
                </div>
                <hr className={style.borderBottom} />
                <div className="d-flex">
                  {professor.linkedin && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>LinkedIn</Tooltip>}>
                      <a href={professor.linkedin} target="_blank" rel="noopener noreferrer" className="me-2">
                        <FaLinkedin style={{ color: "black" }} />
                      </a>
                    </OverlayTrigger>
                  )}
                  {professor.google_scholar && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Google Scholar</Tooltip>}>
                      <a href={professor.google_scholar} target="_blank" rel="noopener noreferrer" className="me-2">
                        <FaGoogle style={{ color: "black" }} />
                      </a>
                    </OverlayTrigger>
                  )}
                  {professor.orcid && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>ORCID</Tooltip>}>
                      <a href={professor.orcid} target="_blank" rel="noopener noreferrer" className="me-2">
                        <FaOrcid style={{ color: "black" }} />
                      </a>
                    </OverlayTrigger>
                  )}
                  {professor.research_gate && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>ResearchGate</Tooltip>}>
                      <a href={professor.research_gate} target="_blank" rel="noopener noreferrer" className="me-2">
                        <FaResearchgate style={{ color: "black" }} />
                      </a>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </Col>

            {/* Tabbed Section for Curriculum Vitae and Books */}
            <Col xs={12} md={7} className={style.contentWrapper}>
              <Tabs defaultActiveKey="cv" id="professor-details-tabs" className="mb-3">
                <Tab eventKey="cv" title={t("professorDetail.curriculumVitae")}>
                  <h5>{t("professorDetail.curriculumVitae")}</h5>
                  <hr className={style.borderBottom} />
                  <div dangerouslySetInnerHTML={{ __html: professor.cv || `<p>${t("professorDetail.notAvailable")}</p>` }} className={style.htmlWrapper} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProfessorDetail;
