import React, { useEffect, useState } from "react";
import "../styles/global.css";
import { fetchDekanatIzjave } from "../services/apiService";
import style from "./DekanatIzjaveShared.module.css";
import { useTranslation } from "react-i18next";

const DekanatStatementsShared = () => {
  const [statements, setStatements] = useState([]);

  const { i18n } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchDekanatIzjave(); // Fetch data using the service function
        setStatements(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    loadData();
  }, [i18n.language]);

  return (
    <div className="row justify-content-evenly" style={{ rowGap: "24px" }}>
      {statements.map((item, index) => (
        <div
          key={index}
          className={`${style.statementsWrapper} col-12 col-md-5 col-lg-5 d-flex`}
        >
          <div className="d-flex flex-column justify-content-between">
            {/* Statement Text */}
            <p className={`${style.statementsText} mb-5`}>
              <i>"{item.statement}"</i>
            </p>

            {/* Staff Info */}
            <div class="d-flex align-items-center border-top pt-2">
              <img
                src={item.staff.image}
                alt={item.staff.full_name}
                className={style.profileImage}
              />
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href =
                    localStorage.getItem("i18nextLng") === "en"
                      ? `/en/fakultet/nastavno-osoblje/${item.staff.slug}`
                      : `/fakultet/nastavno-osoblje/${item.staff.slug}`;
                }}
              >
                <h5 class="fs-5 mb-1 text-black">
                  {item.staff.short_rank} {item.staff.full_name}
                </h5>
                <p className={style.positionSubtitle}>{item.position}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DekanatStatementsShared;
