import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// Function to detect language based on URL and set it in localStorage
const detectLanguageFromPath = () => {
  const pathname = window.location.pathname;

  // Check if URL contains "/en"
  if (pathname.includes("/en")) {
    localStorage.setItem("i18nextLng", "en"); // Store "en" in localStorage
    return "en"; // Return English
  }

  // Default to Bosnian if "/en" is not in the path
  localStorage.setItem("i18nextLng", "bs"); // Store "bs" in localStorage
  return "bs"; // Return Bosnian
};

// Initialize i18n
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "bs", // Default to Bosnian if no language is detected
    detection: {
      order: ["localStorage", "navigator"], // First check localStorage, then fallback to navigator language
      caches: ["localStorage"], // Cache the detected language in localStorage
      lookupLocalStorage: "i18nextLng", // Look for the language in localStorage
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
    },
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
    lng: detectLanguageFromPath(), // Detect language based on the URL and initialize i18next
  });

export default i18n;
