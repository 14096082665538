import React, { useRef } from "react";
import { Helmet } from "react-helmet-async";
import AsocijacijaLogo from "../../../assets/mef-slike/asocijacija.jpg";
import MainBanner from "../../../components/Baner";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import OsobljeKartice from "./OsobljeKartice";
import AsocijacijaSlika from "../../../assets/mef-slike/masinac_logo.jpg";
import style from './Masinac.module.css'

import { useTranslation } from "react-i18next";

import Kontakt from "./Kontakt";

const AsocijacijaComponent = () => {
  const bannerRef = useRef(null);
  const { t } = useTranslation();

  const items = [
    {
      nameKey: "asocijacija.onama",
      link: "#oasocijaciji",
    },
    {
      nameKey: "asocijacija.osoblje",
      link: "#asocijacija-osoblje",
    },

    {
      nameKey: "asocijacija.kontakt",
      link: "#asocijacija-kontakt",
    },
  ];

  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("asocijacija.metaTitle")}</title>
        <meta name="description" content={ t("asocijacija.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("asocijacija.title")}/>
        <meta property="og:description" content={t("asocijacija.ogMetaDescription")}  />
        <meta property="og:image" content={`${window.location.origin}${AsocijacijaSlika}`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <div ref={bannerRef}>
        <MainBanner
          bannerImage={AsocijacijaLogo}
          title={t("asocijacija.title")}
          description={t("asocijacija.description")}
        />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />
      <section className="bg-white container__wrapper" id="oasocijaciji">
        <div className="container d-flex flex-wrap justify-content-between align-items-center html__text">
          <div className="col-md-7">
            <p className="card_subtitle">{t("asocijacija.content1")}</p>
            <p className="card_subtitle">{t("asocijacija.content2")}</p>
          </div>
          <div className="col-md-4">
            <img src={AsocijacijaSlika} alt={"Asocijacija slika"} className={style.logoImage}/>
          </div>
        </div>
      </section>
      <div className="container__wrapper bg-light" id="asocijacija-osoblje">
        <OsobljeKartice />
      </div>
      <div className="container__wrapper bg-white" id="asocijacija-kontakt">
        <Kontakt />
      </div>
    </>
  );
};

export default AsocijacijaComponent;
