import React, { useState, useEffect } from "react";
import { getCV, updateCV } from "../../../services/userProfileService";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Biography.css";

const Biography = () => {
  const [cv, setCv] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showHtml, setShowHtml] = useState(false);

  useEffect(() => {
    const fetchCV = async () => {
      try {
        const response = await getCV();
        setCv(response.cv || "");
        validateCV(response.cv || "");
      } catch (error) {
        toast.error("Greška pri dohvaćanju biografije.");
      }
    };
    fetchCV();
  }, []);

  const handleChange = (value) => {
    setCv(value);
    validateCV(value);
  };

  const validateCV = (content) => {
    const forbiddenTags = /<\s*(script|iframe)[^>]*>/i;
    const hasValidHtml = /<(?!script|iframe)[a-z][\s\S]*>/i.test(content);

    if (forbiddenTags.test(content)) {
      setIsValid(false);
      toast.error(
        "Biografija ne smije sadržavati <script> ili <iframe> tagove."
      );
    } else {
      setIsValid(hasValidHtml);
    }
  };

  const handleSubmit = async () => {
    if (!isValid) {
      toast.error("Biografija mora sadržavati barem jedan HTML tag.");
      return;
    }

    try {
      setIsLoading(true);
      await updateCV({ cv });
      toast.success("Biografija uspješno ažurirana!");
    } catch (error) {
      toast.error("Greška pri ažuriranju biografije.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="biography-container bg-light">
      <h2 className="mb-4">Biografija</h2>
      <Card className="shadow-sm p-4 rounded">
        {showHtml ? (
          <textarea
            className="source-textarea"
            value={cv}
            onChange={(e) => handleChange(e.target.value)}
          />
        ) : (
          <ReactQuill
            value={cv}
            onChange={handleChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ color: [] }, { background: [] }],
                ["bold", "italic", "underline", "strike"],
                [{ script: "sub" }, { script: "super" }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ align: [] }],
                ["blockquote", "code-block"],
                [{ direction: "rtl" }],
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "color",
              "background",
              "bold",
              "italic",
              "underline",
              "strike",
              "script",
              "list",
              "bullet",
              "indent",
              "align",
              "blockquote",
              "code-block",
              "direction",
              "link",
              "image",
              "video",
            ]}
          />
        )}

        <div className="d-flex justify-content-end mt-4">
          {/* Toggle Button */}
          <Button
            variant="info"
            className="me-3"
            onClick={() => setShowHtml(!showHtml)}
          >
            {showHtml ? "Text Editor" : "Source Code"}
          </Button>
          <Button
            type="button"
            variant="primary"
            className="btn-save"
            onClick={handleSubmit}
            disabled={isLoading || !isValid}
          >
            {isLoading ? "Čuvanje..." : "Sačuvaj promjene"}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Biography;
