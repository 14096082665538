import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import DOMPurify from "dompurify";
import DocumentsSection from "../../../components/DocumentCard";
import "../../../styles/global.css";
import Gallery from "../../../components/Galerija";
import { fetchKonferencijeDetail } from "../../../services/apiService";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import HeadingComponent from "../../../components/HeadingComponent";
import style from "../Novosti/NovostiDetaljno.module.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const KonferencijeDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [konferencijeDetail, setKonferencijeDetail] = useState(null);
  const [error, setError] = useState(null);
  const bannerRef = useRef(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchKonferencije = async (slug) => {
      try {
        const data = await fetchKonferencijeDetail(slug);
        setKonferencijeDetail(data);

        const updatedPath =
          i18n.language === "en"
            ? `/en/aktuelnosti/konferencije/${data.slug}`
            : `/aktuelnosti/konferencije/${data.slug}`;
        window.history.replaceState({}, "", updatedPath);
      } catch (error) {
        navigate("/not-found", { replace: true });
        setError("Failed to load news details. Please try again later.");
      }
    };

    fetchKonferencije(slug);
  }, [i18n.language, navigate, slug]);

  if (error)
    return <p style={{ padding: "80px", textAlign: "center" }}>{error}</p>;

  if (!konferencijeDetail)
    return <p style={{ padding: "80px", textAlign: "center" }}>Loading...</p>;

  // Prepare Navbar items based on available data
  const items = [
    { nameKey: "konferencije.content", link: "#sadrzaj" },
    ...(konferencijeDetail.documents && konferencijeDetail.documents.length > 0
      ? [{ nameKey: "konferencije.documents", link: "#dokumenti" }]
      : []),
    ...(konferencijeDetail.gallery && konferencijeDetail.gallery.length > 0
      ? [{ nameKey: "konferencije.gallery", link: "#galerija" }]
      : []),
  ];

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{konferencijeDetail.meta_title}</title>
        <meta name="description" content={konferencijeDetail.meta_description} />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={konferencijeDetail.meta_description} />
        <meta property="og:description" content={ t("meta.og.title")} />
        <meta property="og:image" content={konferencijeDetail.image} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>


      <HeadingComponent
        bannerRef={bannerRef}
        title={konferencijeDetail.title}
        date={konferencijeDetail.date}
        image={konferencijeDetail.image}
        sectionName={t("konferencijeDetaljno.konferencije")}
        fallbackRoute={`${
          i18n.language === "en" ? "/en/" : "/"
        }aktuelnosti/konferencije/`}
      />

      <SectionNavbar items={items} observeRef={bannerRef} />

      {/* Konferencije Content Section */}
      <div className="container__wrapper bg-light html__text">
        <Container id="sadrzaj">
          <div className={style.newsContent}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(konferencijeDetail.content),
              }}
            />
          </div>
          {konferencijeDetail.documents &&
            konferencijeDetail.documents.length > 0 && (
              <div id="dokumenti">
                <DocumentsSection
                  documents={konferencijeDetail.documents}
                  id="dokumenti"
                />
              </div>
            )}
        </Container>
      </div>

      {/* Gallery Section */}
      {konferencijeDetail.gallery && konferencijeDetail.gallery.length > 0 && (
        <Gallery images={konferencijeDetail.gallery} />
      )}
    </div>
  );
};

export default KonferencijeDetail;
