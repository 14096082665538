import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";
import { fetchNovosti } from "../../services/apiService";
import styles from "./Vijesti.module.css";
import { useTranslation } from "react-i18next";
import { truncateText } from "../../helpers/utils";

// Custom arrow components
const NextArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.nextArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null} // Disable click if not visible
  >
    &rarr;
  </div>
);

const PrevArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.prevArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null} // Disable click if not visible
  >
    &larr;
  </div>
);

const NewsCarousel = () => {
  const [novosti, setNovosti] = useState([]);
  const [isNextArrowVisible, setIsNextArrowVisible] = useState(true);
  const [isPrevArrowVisible, setIsPrevArrowVisible] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchNovosti(); // Fetch data using the service function
        setNovosti(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    loadData();
  }, [i18n.language]);

  const handleSlideChange = (current) => {
    const slidesToShow = window.innerWidth > 768 ? 3 : 1;
    const totalSlides = novosti.length;

    setIsPrevArrowVisible(current > 0);
    setIsNextArrowVisible(current + slidesToShow < totalSlides); // Show Next arrow if not on the last slide
  };

  const sliderSettings = (isDesktop) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isDesktop ? 3 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow visible={isNextArrowVisible} />,
    prevArrow: <PrevArrow visible={isPrevArrowVisible} />,
    afterChange: handleSlideChange,
  });

  return (
    <section className={`container__wrapper bg-white`} id="novosti">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
          <div className="mb-3">
            <h2 className="section_title">{t("newsSection.title")}</h2>
            <p className="section_subtitle">{t("newsSection.subtitle")}</p>
          </div>
          <Link
            to={`/aktuelnosti/novosti`}
            style={{ textDecoration: "none", color: "inherit" }}
            className="section__buttons"
          >
            <ButtonComponent
              label={t("newsSection.buttonText")}
              color="#ffffff"
              backgroundColor="#051435"
            />
          </Link>
        </div>

        <Slider {...sliderSettings(window.innerWidth > 768)}>
          {novosti.map((news, index) => (
            <div key={index} style={{ padding: "0 30px" }}>
              <Link
                to={`/aktuelnosti/novosti/${news.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() =>
                  localStorage.setItem(
                    "detailSlug",
                    JSON.stringify({
                      bsSlug: news.slug_bs,
                      enSlug: news.slug_en,
                    })
                  )
                }
              >
                <div className={styles.cardContainer}>
                  <img
                    src={news.image}
                    alt={news.title}
                    className={styles.cardImage}
                  />
                  <div className={styles.cardDate}>{news.date}</div>
                  <div className={styles.cardContent}>
                    <h5 className={styles.cardTitle}>
                      {truncateText(news.title, 120)}
                    </h5>

                    <ButtonComponent
                      label={t("newsSection.readMore")}
                      color="#000"
                      backgroundColor="#9FDAF8"
                    />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default NewsCarousel;
