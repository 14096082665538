import React from "react";
import { Card } from "react-bootstrap";

const ContactCard = ({ title, details, customClass }) => {
  return (
    <Card
      className={`d-flex flex-column justify-content-between ${customClass}`}
      style={{ minHeight: "225px" }}
    >
      <Card.Body className="d-flex flex-column p-0">
        <h5 className="mb-4">{title}</h5>
        <div className="mt-auto">
          {details.map((detail, index) => (
            <p className="mb-2" key={index}>
              {detail.icon} {detail.text}
            </p>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ContactCard;
