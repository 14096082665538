import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import Logo from "../../assets/mef-slike/o-nama-sekcija.jpg";
import BlueBannerComponent from "../../components/PlaviBaner";
import style from "./Onama.module.css";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-light container__wrapper">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Column */}
          <div className="col-lg-5 mb-4 mb-lg-0">
            <img
              src={Logo}
              alt="Masinski Fakultet"
              className={style.imageStyle}
            />
          </div>

          {/* Text Column */}
          <div className="col-lg-7">
            <h2 className="section__title">{t("aboutSection.title")}</h2>
            <p className="section__subtitle">{t("aboutSection.paragraph1")}</p>
            <p className="section__subtitle">{t("aboutSection.paragraph2")}</p>

            <Link
              to={`/fakultet`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ButtonComponent
                label={t("aboutSection.buttonText")}
                color="#000"
                backgroundColor="#9FDAF8"
              />
            </Link>
          </div>
        </div>
      </div>

      {/* Banner Section */}
      <BlueBannerComponent />
    </section>
  );
};

export default AboutSection;
