import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import MainBanner from "../../../components/Baner";
import fondoviBanner from "../../../assets/mef-slike/fondovi_hr.jpg";
import { useTranslation } from "react-i18next";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import FondoviLista from "./FondoviLista";
import { fetchAllFondovi } from "../../../services/apiService";

const FondoviComponent = () => {
  const [fondovi, setFondovi] = useState([]);
  const bannerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const items = [
    { nameKey: "fondovi.about", link: "#ofondovima" },
    { nameKey: "fondovi.list", link: "#fondovi" },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchAllFondovi();
        setFondovi(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    loadData();
  }, [i18n.language]);

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("fondovi.metaTitle")}</title>
        <meta name="description" content={ t("fondovi.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("fondovi.metaTitle")}/>
        <meta property="og:description" content={t("fondovi.ogMetaDescription")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        bannerRef={bannerRef}
        title={t("fondovi.title")}
        styleImage={true}
        bannerImage={fondoviBanner}
        description={t("fondovi.description")}
      />
      <SectionNavbar items={items} observeRef={bannerRef} />

      <section className="bg-light container__wrapper" id="ofondovima">
        <div className="container d-flex flex-wrap justify-content-between align-items-center html__text">
          <div className="col-md-7">
            <p className="card_subtitle">{t("fondovi.content1")}</p>
            <p className="card_subtitle">{t("fondovi.content2")}</p>
          </div>
        </div>
      </section>
      <div className="container__wrapper bg-white" id="fondovi">
        <FondoviLista fondovi={fondovi} />
      </div>
    </div>
  );
};

export default FondoviComponent;
