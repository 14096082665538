export function truncateText(text, maxLength = 95) {
  if (typeof text !== "string") return ""; // Ensure text is a string, or return an empty string if undefined/null

  if (text.length <= maxLength) return text;

  let trimmedText = text.substring(0, maxLength).trim();

  // Ensure we don't cut a word in half
  let lastSpaceIndex = trimmedText.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    trimmedText = trimmedText.substring(0, lastSpaceIndex);
  }

  return trimmedText + "...";
}
