import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import DOMPurify from "dompurify";
import "../../../styles/global.css";
import Gallery from "../../../components/Galerija";
import { fetchMasnijadaDetail } from "../../../services/apiService";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import HeadingComponent from "../../../components/HeadingComponent";
import style from "../Novosti/NovostiDetaljno.module.css";
import { useTranslation } from "react-i18next";
import "./MasinijadaDetaljno.css";
import MasinijadaLista from "./MasinijadaLista";
import { Helmet } from "react-helmet-async";


const NewsDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [masinijadaDetail, setMasinijadaDetail] = useState(null);
  const [masinijade, setMasinijade] = useState([]);
  const [error, setError] = useState(null);
  const bannerRef = useRef(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchMasinijada = async (slug) => {
      try {
        const data = await fetchMasnijadaDetail(slug);
        setMasinijadaDetail(data);
        setMasinijade(data.other_masinijade);

        const updatedPath =
          i18n.language === "en"
            ? `/en/aktuelnosti/masinijade/${data.slug}`
            : `/aktuelnosti/masinijade/${data.slug}`;
        window.history.replaceState({}, "", updatedPath);
      } catch (error) {
        // navigate("/not-found", { replace: true });
        setError("Failed to load news details. Please try again later.");
      }
    };

    fetchMasinijada(slug);
  }, [i18n.language, navigate, slug]);

  if (error)
    return <p style={{ padding: "80px", textAlign: "center" }}>{error}</p>;

  if (!masinijadaDetail)
    return <p style={{ padding: "80px", textAlign: "center" }}>Loading...</p>;

  // Prepare Navbar items based on available data
  const items = [
    { nameKey: "masinijada.content", link: "#sadrzaj" },

    ...(masinijadaDetail.gallery && masinijadaDetail.gallery.length > 0
      ? [{ nameKey: "masinijada.gallery", link: "#galerija" }]
      : []),
    { nameKey: "masinijada.prethodne", link: "#prethodne" },
  ];

  const sanitizedContent = DOMPurify.sanitize(masinijadaDetail.content, {
    FORBID_ATTR: ["style"],
  });

  const masinijada_title = masinijadaDetail.meta_title +  t('meta.title')


  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{masinijada_title}</title>
        <meta name="description" content={masinijadaDetail.meta_description} />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={masinijada_title} />
        <meta property="og:description" content={masinijadaDetail.meta_description} />
        <meta property="og:image" content={masinijadaDetail.image} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <HeadingComponent
        bannerRef={bannerRef}
        title={masinijadaDetail.title}
        date={masinijadaDetail.date}
        image={masinijadaDetail.image}
        sectionName={t("masinijada.masinijade")}
        fallbackRoute={`${
          i18n.language === "en" ? "/en/" : "/"
        }aktuelnosti/masinijade`}
      />

      <SectionNavbar items={items} observeRef={bannerRef} />

      {/* News Content Section */}
      <div className="container__wrapper bg-light html__text">
        <Container id="sadrzaj">
          <Card className={`${style.cardWrapper} mb-4`}>
            <Card.Body>
              <Card.Text className="fs-6 fw-semibold text-dark">
                {masinijadaDetail.summary}
              </Card.Text>
            </Card.Body>
          </Card>

          <div className={style.newsContent} id="masinijada_content">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizedContent,
              }}
            />
          </div>
        </Container>
      </div>

      {/* Gallery Section */}
      {masinijadaDetail.gallery && masinijadaDetail.gallery.length > 0 && (
        <Gallery images={masinijadaDetail.gallery} />
      )}
      <div className="container__wrapper bg-light" id="prethodne">
        <MasinijadaLista masinijade={masinijade} />
      </div>
    </div>
  );
};

export default NewsDetail;
