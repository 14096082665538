import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  Dropdown,
  DropdownButton,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { fetchAllDocuments, getVijeceFile } from "../../../services/apiService";
import MainBanner from "../../../components/Baner";
import dokumentiLogo from "../../../assets/dokumenti.jpg";
import { AuthContext } from "../../../contexts/AuthContext";
import style from "./Dokumenti.module.css";
import { useTranslation } from "react-i18next";
import SharedPagination from "../../../components/SharedPagination";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";


const Dokumenti = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const { isAuthenticated } = useContext(AuthContext);

  const [selectedFirstFilter, setSelectedFirstFilter] = useState(
    !isAuthenticated ? "Svi dokumenti" : "Javnost rada fakulteta"
  );
  const [selectedSecondFilter, setSelectedSecondFilter] = useState(
    !isAuthenticated ? "Sve kategorije" : "Materijali za Vijeća"
  );
  const [searchQuery, setSearchQuery] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("page"), "params");

  const page = parseInt(searchParams.get("page") || "1", 10);

  const firstFilterOptions = [
    {
      value: "Svi dokumenti",
      label: t("dokumenti.firstDropdown.sviDokumenti"),
    },
    {
      value: "Javnost rada fakulteta",
      label: t("dokumenti.firstDropdown.javnostRada"),
    },
    {
      value: "Propisi",
      label: t("dokumenti.firstDropdown.propisi"),
    },
  ];

  const secondFilterOptions = {
    "Svi dokumenti": [
      {
        value: "Sve kategorije",
        label: t("dokumenti.secondDropdown.sviDokumenti.sve"),
      },
      {
        value: "Zakoni",
        label: t("dokumenti.secondDropdown.sviDokumenti.zakoni"),
      },
      {
        value: "Izvještaji",
        label: t("dokumenti.secondDropdown.sviDokumenti.izvjestaji"),
      },
      {
        value: "Propisi",
        label: t("dokumenti.secondDropdown.sviDokumenti.propisi"),
      },
      {
        value: "Pravilnici",
        label: t("dokumenti.secondDropdown.sviDokumenti.pravilnici"),
      },
      {
        value: "Odluke",
        label: t("dokumenti.secondDropdown.sviDokumenti.odluke"),
      },
      {
        value: "Obrasci",
        label: t("dokumenti.secondDropdown.sviDokumenti.obrasci"),
      },
      {
        value: "Konkursi",
        label: t("dokumenti.secondDropdown.sviDokumenti.konkursi"),
      },
      {
        value: "Zahtjevi",
        label: t("dokumenti.secondDropdown.sviDokumenti.zahtijevi"),
      },
      {
        value: "Ostalo",
        label: t("dokumenti.secondDropdown.sviDokumenti.ostalo"),
      },
    ],
    Propisi: [
      {
        value: "Bosne i Hercegovine",
        label: t("dokumenti.secondDropdown.propisi.bih"),
      },
      {
        value: "Federacije Bosne i Hercegovine",
        label: t("dokumenti.secondDropdown.propisi.fbih"),
      },
      {
        value: "Kantona Sarajevo",
        label: t("dokumenti.secondDropdown.propisi.ks"),
      },
      {
        value: "Univerziteta u Sarajevu",
        label: t("dokumenti.secondDropdown.propisi.unsa"),
      },
    ],
    "Javnost rada fakulteta": [
      {
        value: "Izvještaji komisije za izbore u akademska zvanja",
        label: t("dokumenti.secondDropdown.javnostRada.izvjestajiKomisije"),
      },
      {
        value: "Sjednice Vijeća",
        label: t("dokumenti.secondDropdown.javnostRada.dnevniRedVijeca"),
      },
      {
        value: "Materijali za Vijeća",
        label: t("dokumenti.secondDropdown.javnostRada.materijaliVijeca"),
      },
      {
        value: "Konkursi za izbore u akademska zvanja",
        label: t("dokumenti.secondDropdown.javnostRada.konkursiAkademska"),
      },
      {
        value: "Konkursi za prijem neakademskog osoblja",
        label: t("dokumenti.secondDropdown.javnostRada.konkursiNeakademska"),
      },
      {
        value: "Angažman akademskog osoblja",
        label: t("dokumenti.secondDropdown.javnostRada.angazmanAkademska"),
      },
      {
        value: "Sporazumi o akademskoj saradnji",
        label: t("dokumenti.secondDropdown.javnostRada.akademskaSaradnja"),
      },
      {
        value: "Odgovori na zastupnička pitanja",
        label: t("dokumenti.secondDropdown.javnostRada.odgovoriZastupnicka"),
      },
    ],
  };

  useEffect(() => {
    if (isAuthenticated) {
      setSelectedFirstFilter("Javnost rada fakulteta");
      setSelectedSecondFilter("Materijali za Vijeća");
    }
  }, [isAuthenticated]);

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const data = await fetchAllDocuments(
        page,
        searchQuery,
        selectedFirstFilter,
        selectedSecondFilter
      );
      setDocuments(data.results);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [selectedFirstFilter, selectedSecondFilter, page, searchQuery]);

  const handleFirstFilterChange = (filter) => {
    setSelectedFirstFilter(filter);

    setSearchParams({ page: 1 });

    if (filter === "Propisi" || filter === "Javnost rada fakulteta") {
      setSelectedSecondFilter(secondFilterOptions[filter][0].value);
    } else {
      setSelectedSecondFilter("Sve kategorije");
    }
  };

  const handleSecondFilterChange = (filter) => {
    setSelectedSecondFilter(filter);
    setSearchParams({ page: 1 });
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    // Update URL query parameter for page change
    setSearchParams({ page: newPage });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSearchParams({ page: 1 });
  };

  const handleDownloadClick = async (file_name) => {
    try {
      setLoading(true);
      await getVijeceFile(file_name);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(count / 10);


  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("dokumenti.metaTitle")}</title>
        <meta name="description" content={ t("dokumenti.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("dokumenti.metaTitle")}/>
        <meta property="og:description" content={t("dokumenti.ogMetaDescription")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        bannerImage={dokumentiLogo}
        title={t("dokumenti.banner.title")}
        description={t("dokumenti.banner.description")}
      />
      <div
        className="container__wrapper bg-white "
        style={{ minHeight: "600px" }}
      >
        <div className="container">
          <div className="d-flex flex-wrap justify-content-between mb-4">
            <div className="d-flex flex-wrap flex-lg-nowrap gap-2 col-12 col-lg-6">
              {/* First Dropdown Button */}
              <div className="col-12 col-lg-auto mb-2 mb-lg-0">
                <DropdownButton
                  title={
                    firstFilterOptions.find(
                      (option) => option.value === selectedFirstFilter
                    )?.label || selectedFirstFilter
                  }
                  variant="white"
                  onSelect={(eventKey) => handleFirstFilterChange(eventKey)}
                  className={`${style.dropDownStyle} w-100 `}
                >
                  {firstFilterOptions.map((option) => (
                    <Dropdown.Item
                      key={option.value}
                      eventKey={option.value}
                      className="w-100"
                    >
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              {/* Second Dropdown Button */}
              <div className="col-12 col-lg-auto mb-2 mb-lg-0">
                <DropdownButton
                  title={
                    secondFilterOptions[selectedFirstFilter].find(
                      (option) => option.value === selectedSecondFilter
                    )?.label || selectedSecondFilter
                  }
                  variant="white"
                  onSelect={(eventKey) => handleSecondFilterChange(eventKey)}
                  className={`${style.dropDownStyle} w-100`}
                >
                  {secondFilterOptions[selectedFirstFilter].map((option) => {
                    if (
                      option.value === "Materijali za Vijeća" &&
                      !isAuthenticated
                    ) {
                      return null;
                    }
                    return (
                      <Dropdown.Item
                        key={option.value}
                        eventKey={option.value}
                        className="w-100"
                        style={{
                          fontWeight:
                            option.value === "Materijali za Vijeća"
                              ? 600
                              : "normal",
                        }}
                      >
                        {option.label}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>

            {/* Search Input */}
            <div className="col-12 col-lg-auto mt-2 mt-lg-0">
              <InputGroup style={{ width: "100%" }}>
                <FormControl
                  placeholder={t("dokumenti.search")}
                  value={searchQuery}
                  onChange={handleSearch}
                  className={style.searchInputStyle}
                />
              </InputGroup>
            </div>
          </div>

          <Table hover style={{ borderTop: "none" }}>
            <thead>
              <tr style={{ backgroundColor: "#F3F6F8" }}>
                <th className={style.tableHeadingStyle}>
                  {t("dokumenti.table.title")}
                </th>
                {selectedSecondFilter !== "Materijali za Vijeća" && (
                  <th className={style.tableHeadingStyle}>
                    {t("dokumenti.table.category")}
                  </th>
                )}
                <th className={style.tableHeadingStyle}>
                  {t("dokumenti.table.publicationDate")}
                </th>
                <th
                  className={`${style.tableHeadingStyle} ${style.downloadHeading}`}
                >
                  {t("dokumenti.table.download")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div className="d-flex justify-content-center align-items-center my-5">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  </td>
                </tr>
              ) : documents.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    {t("dokumenti.noResults")}
                  </td>
                </tr>
              ) : (
                documents.map((doc) => (
                  <tr
                    key={doc.title}
                    style={{ borderBottom: "1px solid #DDDDDD" }}
                  >
                    <td className={style.tableDataStyle}>{doc.title}</td>
                    {selectedSecondFilter !== "Materijali za Vijeća" && (
                      <td className={style.tableDataStyle}>{doc.doc_type}</td>
                    )}
                    <td className={style.tableDataStyle}>{doc.date}</td>
                    <td
                      className={style.tableDataStyle}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderBottom: "none",
                      }}
                    >
                      {selectedSecondFilter === "Materijali za Vijeća" ? (
                        <button
                          onClick={() => handleDownloadClick(doc.file_name)}
                          className={style.downloadButtonStyle}
                        >
                          <FaDownload />
                        </button>
                      ) : (
                        <a
                          href={doc.file_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "black" }}
                        >
                          <FaDownload />
                        </a>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>

          <SharedPagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Dokumenti;
