import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import { FaEnvelope, FaPhone, FaUserCircle } from "react-icons/fa";

const ContactCard = ({ voditelji = [] }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Row className="justify-content-start">
        {voditelji.map((voditelj, index) => (
          <Col key={index} xs={12} sm={8} md={6} lg={5} className="mb-4">
            <Card
              className="p-4 rounded-lg"
              style={{ border: "1px solid #F3F6F8" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ cursor: voditelj.slug ? "pointer" : "default" }}
                onClick={() => voditelj.slug && navigate(`/${voditelj.slug}`)}
              >
                {voditelj.image ? (
                  <img
                    src={voditelj.image}
                    alt={voditelj.full_name}
                    className="rounded-circle me-3 professor-image"
                  />
                ) : (
                  <FaUserCircle size={60} className="me-3 text-muted" />
                )}
                <div>
                  <h5 className="mb-0 font-weight-bold">
                    {voditelj.full_name}
                  </h5>
                  <p className="text-muted mb-0">{voditelj.position}</p>
                </div>
              </div>
              <hr className="mb-5" />
              <div>
                <p className="mb-2 d-flex align-items-center">
                  <FaEnvelope className="me-2" />
                  <a
                    href={`mailto:${voditelj.email}`}
                    className="text-dark text-decoration-none"
                  >
                    {voditelj.email}
                  </a>
                </p>
                <p className="mb-0 d-flex align-items-center">
                  <FaPhone className="me-2" />
                  <a
                    href={`tel:${voditelj.phone}`}
                    className="text-dark text-decoration-none"
                  >
                    {voditelj.phone}
                  </a>
                </p>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ContactCard;
