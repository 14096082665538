import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaCity,
  FaGlobe,
  FaEnvelope,
  FaPhone,
  FaClock,
} from "react-icons/fa";
import ContactCard from "../../../components/KontaktKartica";
import { useTranslation } from "react-i18next";
import styles from "../../Pocetna/Kontakt.module.css";

const ContactInfoSection = () => {
  const { t } = useTranslation();

  const contactData = [
    {
      title: t("contact.addressTitle"),
      details: [
        {
          icon: <FaMapMarkerAlt className="me-2" />,
          text: "Vilsonovo šetalište 9",
        },
        { icon: <FaCity className="me-2" />, text: "71000 Sarajevo" },
        { icon: <FaGlobe className="me-2" />, text: "Bosna i Hercegovina" },
      ],
    },
    {
      title: t("contact.facultyTitle"),
      details: [
        {
          icon: <FaEnvelope className="me-2" />,
          text: "info@mef.unsa.ba",
        },
        {
          icon: <FaPhone className="me-2" />,
          text: "+387 33 729 800",
        },
        {
          icon: <FaClock className="me-2" />,
          text: t("contact.facultyWorkTime"),
        },
      ],
    },
    {
      title: t("contact.studentServiceTitle"),
      details: [
        {
          icon: <FaEnvelope className="me-2" />,
          text: "studentska_sluzba@mef.unsa.ba",
        },
        {
          icon: <FaPhone className="me-2" />,
          text: "+387 33 729 813",
        },
        {
          icon: <FaClock className="me-2" />,
          text: t("contact.studentServiceWorkTime"),
        },
      ],
    },
    {
      title: t("contact.libraryTitle"),
      details: [
        {
          icon: <FaEnvelope className="me-2" />,
          text: "biblioteka@mef.unsa.ba",
        },
        {
          icon: <FaPhone className="me-2" />,
          text: "+387 33 729 814",
        },
        {
          icon: <FaClock className="me-2" />,
          text: t("contact.libaryWorkTime"),
        },
      ],
    },
  ];

  return (
    <Container>
      <Row className="px-0">
        {contactData.map((item, index) => (
          <Col md={4} sm={12} className="mb-4" key={index}>
            <ContactCard
              title={item.title}
              details={item.details}
              customClass={styles.cardCustom}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ContactInfoSection;
