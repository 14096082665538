import React, { useEffect } from "react";
import NastavnoOsobljeList from "./NastavnoOsobljeList";
import MainBanner from "../../../components/Baner";
import osobljeBanner from "../../../assets/nastavno-osoblje.jpg";
import { useTranslation } from "react-i18next"; // Import i18n hook
import { Helmet } from "react-helmet-async";


const NastavnoOsobljeComponent = () => {
  const { t, i18n } = useTranslation(); // Access translation and language functions

  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("nastavnoOsobljeBanner.metaTitle")}</title>
        <meta name="description" content={ t("nastavnoOsobljeBanner.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("nastavnoOsobljeBanner.metaTitle")}/>
        <meta property="og:description" content={t("nastavnoOsobljeBanner.ogMetaDescription")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>
      
      <MainBanner
        title={t("nastavnoOsobljeBanner.bannerTitle")}
        bannerImage={osobljeBanner}
        description={t("nastavnoOsobljeBanner.bannerDescription")}
      />
      <NastavnoOsobljeList />
    </>
  );
};

export default NastavnoOsobljeComponent;
