import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import MainBanner from "../../../components/Baner";
import ButtonComponent from "../../../components/ButtonComponent";
import { fetchAllStudentskeNovosti } from "../../../services/apiService";
import novostiBanner from "../../../assets/mef-slike/studentski_blog.jpg";
import style from "../../Aktuelnosti/Novosti/NovostiLista.module.css";
import { useTranslation } from "react-i18next";
import { truncateText } from "../../../helpers/utils";
import SharedPagination from "../../../components/SharedPagination";
import { Helmet } from "react-helmet-async";


const StudentskiBlogComponent = () => {
  const [novosti, setNovosti] = useState([]);
  const [newsNum, setNewsNum] = useState(0);
  const [titleFilter, setTitleFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 6;
  const { t, i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || 1, 10);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchAllStudentskeNovosti(currentPage, titleFilter);
        setNewsNum(data.count);
        setNovosti(data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, titleFilter, i18n.language]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setSearchParams({ page });
  };

  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);
    setSearchParams({ page: 1 });
  };

  const totalPages = Math.ceil(newsNum / itemsPerPage);

  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("studentskiBlog.metaTitle")}</title>
        <meta name="description" content={ t("studentskiBlog.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("studentskiBlog.metaTitle")}/>
        <meta property="og:description" content={t("studentskiBlog.description")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        title={t("studentskiBlog.title")}
        styleImage={true}
        bannerImage={novostiBanner}
        description={t("studentskiBlog.description")}
      />
      <div className="container__wrapper bg-white">
        <Container>
          <div className="d-flex justify-content-end mb-4">
            <InputGroup className={style.inputField}>
              <FormControl
                placeholder={t("newsSection.searchLabel")}
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>

          <div className="border-top pt-4">
            <Row className="gy-4">
              {isLoading ? (
                <div className="d-flex justify-content-center my-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : novosti.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <p>{t("studentskiBlog.noData")}</p>
                </div>
              ) : (
                novosti.slice(0, 6).map((news) => (
                  <Col key={news.slug} xs={12} sm={6} md={4}>
                    <Link
                      to={`/aktuelnosti/studenti/${news.slug}`}
                      state={{ fromPage: currentPage }}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className={style.cardWrapper}>
                        <img
                          src={news.image}
                          alt={news.title}
                          className={style.imageStyle}
                        />
                        <div className={style.newsDate}>{news.date}</div>
                        <div className={style.newsContent}>
                          <h5>{truncateText(news.title, 120)}</h5>
                          <p className={style.newsSummary}>
                            {truncateText(news.summary, 160)}
                          </p>
                          <ButtonComponent
                            label={t("newsSection.readMore")}
                            color="#000"
                            backgroundColor="#9FDAF8"
                          />
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))
              )}
            </Row>
          </div>

          <SharedPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Container>
      </div>
    </>
  );
};

export default StudentskiBlogComponent;
