import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  apiPostFormData,
  apiPutFormData,
} from "./apiService";

export const getUserProfile = async () => {
  return await apiGet("/api/user/data");
};

export const getProfileImage = async () => {
  return await apiGet("/api/user/change-profile-image");
};

export const updateUserProfile = async (profileData) => {
  return await apiPut("/api/user/change-names", profileData);
};

export const uploadProfileImage = async (imageFile) => {
  const formData = new FormData();
  formData.append("image", imageFile);
  return await apiPut("/api/user/change-profile-image", formData, true);
};

export const deleteProfileImage = async () => {
  return await apiDelete("/api/user/change-profile-image");
};

export const changeUserPassword = async (passwordData) => {
  return await apiPost("/api/user/change-password", passwordData);
};

export const getTeachingData = async () => {
  return await apiGet("/api/user/nastavno-osoblje/data");
};

export const updateTeachingData = async (data) => {
  return await apiPut("/api/user/nastavno-osoblje/data", data);
};

export const getCV = async () => {
  return await apiGet("/api/user/nastavno-osoblje/cv");
};

export const updateCV = async (cvData) => {
  return await apiPut("/api/user/nastavno-osoblje/cv", cvData);
};

export const getExamResults = async (page = 1, search = "") => {
  return await apiGet(
    `/api/user/nastavno-osoblje/rezultati-ispita/?page=${page}&search=${search}`
  );
};

export const deleteExamResult = async (id) => {
  return await apiDelete(`/api/user/nastavno-osoblje/rezultati-ispita/${id}`);
};

export const addExamResult = async (formData) => {
  return await apiPostFormData(
    "/api/user/nastavno-osoblje/rezultati-ispita/new",
    formData,
    true
  );
};

export const updateExamResult = async (id, formData) => {
  return await apiPutFormData(
    `/api/user/nastavno-osoblje/rezultati-ispita/${id}`,
    formData,
    true
  );
};

export const getExamById = async (id) => {
  return await apiGet(`/api/user/nastavno-osoblje/rezultati-ispita/${id}`);
};

export const getSubjectList = async (search = "") => {
  return await apiGet(`/api/user/nastavno-osoblje/predmeti/?search=${search}`);
};

export const getTermList = async (search = "") => {
  return await apiGet(`/api/user/nastavno-osoblje/rokovi/?search=${search}`);
};
