import React, { useEffect } from "react";
import RezultatiIspitaList from "./RezultatiIspitaList";
import MainBanner from "../../../components/Baner";
import rezultatiIspitaBanner from "../../../assets/rezultati-ispita.jpg";
import { useTranslation } from "react-i18next"; // Import i18n hook
import { Helmet } from "react-helmet-async";


const NastavnoOsobljeComponent = () => {
  const { t, i18n } = useTranslation(); // Access translation and language functions

  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("rezultatiIspita.metaTitle")}</title>
        <meta name="description" content={ t("rezultatiIspita.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("rezultatiIspita.metaTitle")}/>
        <meta property="og:description" content={t("rezultatiIspita.ogMetaDescription")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        title={t("rezultatiIspita.bannerTitle")}
        bannerImage={rezultatiIspitaBanner}
        description={t("rezultatiIspita.bannerDescription")}
      />
      <RezultatiIspitaList />
    </>
  );
};

export default NastavnoOsobljeComponent;
