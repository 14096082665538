import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useLocation } from "react-router-dom";
import style from "./HeadingComponent.module.css";
const HeadingComponent = ({
  bannerRef,
  title,
  date,
  subtitle,
  image,
  sectionName,
  fallbackRoute,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackNavigation = () => {
    // Check if we have a page number in state
    const fromPage = location.state?.fromPage;

    if (fromPage) {
      // Redirect to the specific page of the news list
      navigate(`${fallbackRoute}?page=${fromPage}`);
    } else {
      // Fallback to the default route if no state exists
      navigate(fallbackRoute);
    }
  };
  return (
    <div ref={bannerRef} class={`${style.headingWrapper} container mb-5`}>
      <div
        className="d-flex align-items-center mb-3"
        style={{ cursor: "pointer", color: "#0094FF" }}
        onClick={handleBackNavigation}
      >
        <span class="me-1 mb-1">&lt;</span>

        <h6 class={style.arrowBack}>{sectionName}</h6>
      </div>
      <h1 class="text-white fs-2 fw-semibold" style={{ maxWidth: "800px" }}>
        {title}
      </h1>

      <p class="fs-6 text-white">{date}</p>

      {image && (
        <img
          src={image}
          alt={title}
          class={`${style.headingImage} img-fluid my-3`}
        />
      )}
    </div>
  );
};

export default HeadingComponent;
