const BASE_URL = process.env.REACT_APP_BASE_URL;

export const loginUser = async (emailOrUsername, password) => {
  try {
    const response = await fetch(`${BASE_URL}/api/user/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email_or_username: emailOrUsername,
        password: password,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw data;
    }

    return data;
  } catch (error) {
    throw error;
  }
};
