import React from "react";
import Map from "./Map";
import KontaktLogo from "../../../assets/mef-slike/kontakt-cover.jpg";
import MainBanner from "../../../components/Baner";
import KontaktKartice from "./KontaktKartice";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const ContactComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("kontakt.metaTitle")}</title>
        <meta name="description" content={ t("kontakt.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("kontakt.metaTitle")}/>
        <meta property="og:description" content={t("kontakt.description")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        bannerImage={KontaktLogo}
        title={t("kontakt.title")}
        description={t("kontakt.description")}
      />
      <div className="container__wrapper bg-white">
        <Map />
      </div>
      <div className="container__wrapper bg-light">
        <KontaktKartice />
      </div>
    </>
  );
};

export default ContactComponent;
