import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.css";
import style from "./Baner.module.css";
import ButtonComponent from "./ButtonComponent";
import { useTranslation } from "react-i18next";

const MainBanner = ({
  title,
  bannerImage,
  description,
  showTwo = false,
  firstParagraph = "",
  secondParagraph = "",
  titleSecond = "",
  styleImage,
  bibliotekaButton = false,
  bannerRef,
  moveImage = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="container-fluid d-flex flex-column container mb-5"
      style={{
        height: "min-content",
        backgroundColor: "transparent",
        color: "white",
        marginTop: "150px",
      }}
      ref={bannerRef}
    >
      <h1 className="heading-1">{title}</h1>
      {showTwo && <h1 className="heading-1">{titleSecond}</h1>}
      {description && (
        <div className="mt-3 mb-3 banner__description">
          <p className="subtitle">{description}</p>
        </div>
      )}
      {bibliotekaButton && (
        <ButtonComponent
          label={t("biblioteka.bibliotekaButton")}
          backgroundColor="#9FDAF8"
          color="#051435"
          onClick={() =>
            window.open("https://biblioteka.mef.unsa.ba/default", "_blank")
          }
        />
      )}

      {showTwo && (
        <div className="d-flex flex-column flex-md-row gap-3 gap-md-5 mt-3 mb-3">
          <p className="subtitle">{firstParagraph}</p>
          <p className="subtitle">{secondParagraph}</p>
        </div>
      )}

      {bannerImage && (
        <img
          src={bannerImage}
          alt="Banner MEF"
          class={`${style.bannerImg} img-fluid mt-4 `}
          style={
            styleImage ? { objectPosition: moveImage ? "center" : "right" } : {}
          }
        />
      )}
    </div>
  );
};

export default MainBanner;
