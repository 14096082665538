import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { FaSearchPlus } from "react-icons/fa"; // Import zoom icon
import "./Galerija.css";

const NextArrow = ({ onClick }) => (
  <div className="arrowStyle nextArrow" onClick={onClick}>
    &rarr;
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="arrowStyle prevArrow" onClick={onClick}>
    &larr;
  </div>
);

const Gallery = ({ images, backgroundColor = "bg-white" }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [useCarousel, setUseCarousel] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Disable carousel if only one image or in desktop view with fewer than 4 images
    setUseCarousel(
      images && images.length > 1 && !(isDesktop && images.length < 4)
    );
  }, [isDesktop, images]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  if (!images || images.length === 0) return null; // Do not render if there are no images

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <div
      style={{
        minHeight: "420px",
        display: "flex",
        alignItems: "center",
      }}
      className={backgroundColor}
      id="galerija"
    >
      <Container style={{ maxWidth: "100%" }}>
        {useCarousel ? (
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} style={{ padding: "0 15px" }}>
                <div className="image__wrapper">
                  <img
                    src={image.image_url}
                    alt={image.alt}
                    className="imgStyle"
                  />
                  {/* Zoom Icon */}
                  <div
                    className="search__icon"
                    onClick={() => handleImageClick(image)}
                  >
                    <FaSearchPlus color="#fff" size={16} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "15px" }}
          >
            {images.map((image, index) => (
              <div key={index} className="galleryCardWrapper">
                <img
                  src={image.image_url}
                  alt={image.alt}
                  className="imgStyle"
                />
                {/* Zoom Icon */}
                <div
                  className="searchIcon"
                  onClick={() => handleImageClick(image)}
                >
                  <FaSearchPlus color="#fff" size={13} />
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Modal for Enlarged Image */}
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          size="lg"
          style={{ borderRadius: "13px" }}
        >
          <Modal.Body style={{ position: "relative", padding: 0 }}>
            <button type="button" onClick={handleClose} className="closeIcon">
              &times;
            </button>
            <img
              src={selectedImage?.image_url}
              alt={selectedImage?.alt}
              className="img-fluid d-block w-100"
            />
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default Gallery;
