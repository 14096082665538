import React, { useState, useEffect } from "react";
import {
  getUserProfile,
  updateUserProfile,
  uploadProfileImage,
  deleteProfileImage,
  getProfileImage,
} from "../../../services/userProfileService";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import "./ProfileForm.css";
import { toast } from "react-toastify";

const ProfileForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    image: "",
  });

  const [initialData, setInitialData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const [userData, profileImage] = await Promise.all([
          getUserProfile(),
          getProfileImage(),
        ]);

        setFormData({ ...userData, image: profileImage.image });
        setInitialData({ ...userData });
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    };

    fetchProfileData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const nameRegex = /^[A-Za-zČčĆćĐđŠšŽž\s-]+$/;
    let error = "";

    if (!value.trim()) {
      error = "Polje ne može biti prazno.";
    } else if (value.length < 2) {
      error = "Mora sadržavati najmanje 2 slova.";
    } else if (!nameRegex.test(value)) {
      error = "Može sadržavati samo slova.";
    }

    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1.5 * 1024 * 1024) {
        toast.error("Veličina slike ne smije biti veća od 1.5MB.");
        return;
      }
      setSelectedImage(URL.createObjectURL(file));
      setIsLoading(true);

      try {
        await uploadProfileImage(file);
        const updatedImage = await getProfileImage();
        setFormData({ ...formData, image: updatedImage.image });
        localStorage.setItem("user_image", updatedImage.image);
        toast.success("Slika je uspješno postavljena.");
      } catch (error) {
        console.error("Image upload failed:", error);
      } finally {
        setSelectedImage(null);
        setIsLoading(false);
      }
    }
  };

  const handleDeleteImage = async () => {
    setIsLoading(true);
    try {
      await deleteProfileImage();
      const updatedImage = await getProfileImage();
      setFormData({ ...formData, image: updatedImage.image });
      localStorage.setItem("user_image", updatedImage.image);
      setSelectedImage(null);
      toast.success("Slika je uspješno obrisana.");
    } catch (error) {
      console.error("Failed to delete image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await updateUserProfile({
        first_name: formData.first_name,
        last_name: formData.last_name,
      });
      const updatedData = await getUserProfile();
      setFormData(updatedData);
      setInitialData(updatedData);
      setIsEditing(false);
      toast.success("Profil uspješno ažuriran!");
    } catch (error) {
      toast.error("Greška prilikom ažuriranja profila.");
    } finally {
      setIsLoading(false);
    }
  };

  const isChanged =
    formData.first_name !== initialData.first_name ||
    formData.last_name !== initialData.last_name;

  return (
    <div className="profile-container bg-light">
      <h2 className="mb-4">Osnovni podaci</h2>
      <Card className="shadow-sm p-4 rounded">
        <div className="d-flex align-items-center mb-4 image-container">
          <div className="profile-img-container">
            <img
              src={selectedImage || formData.image}
              alt="Profile"
              className="profile-img"
            />
          </div>

          <input
            type="file"
            accept="image/*"
            className="d-none"
            id="fileInput"
            onChange={handleImageChange}
          />

          <Button
            variant="primary"
            className="ms-3"
            onClick={() => document.getElementById("fileInput").click()}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Promjeni sliku"}
          </Button>

          {formData.image && !formData.image.includes("avatar") && (
            <Button
              variant="danger"
              className="ms-2"
              onClick={handleDeleteImage}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Izbriši sliku"}
            </Button>
          )}
        </div>
        <hr />

        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Ime</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  disabled={!isEditing}
                  isInvalid={!!errors.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Prezime</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  disabled={!isEditing}
                  isInvalid={!!errors.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Korisničko ime</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="success"
              onClick={handleEdit}
              className="me-2"
              disabled={isEditing}
            >
              Izmijeni podatke
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={
                !isEditing ||
                !isChanged ||
                Object.values(errors).some((error) => error)
              }
            >
              {isLoading ? "Sačuvaj promjene..." : "Sačuvaj promjene"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ProfileForm;
