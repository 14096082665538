import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import MainBanner from "../../components/Baner";
import projektiBanner from "../../assets/mef-slike/projekti-fakultet.jpg";
import { useTranslation } from "react-i18next";
import SectionNavbar from "../../components/NavigacijaSekcije";
import ProjektiLista from "./ProjektiLista";
import { fetchAllProjekti } from "../../services/apiService";

const ProjektiComponent = () => {
  const [projekti, setProjekti] = useState([]);
  const bannerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const items = [
    { nameKey: "projekti.about", link: "#oprojektima" },
    { nameKey: "projekti.projekti", link: "#projekti" },
  ];


  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchAllProjekti();
        setProjekti(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    loadData();
  }, [i18n.language]);

  return (
    <div>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("projekti.metaTitle")}</title>
        <meta name="description" content={ t("projekti.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("projekti.metaTitle")}/>
        <meta property="og:description" content={t("projekti.description")}  />
        <meta property="og:image" content={`${window.location.origin}${projektiBanner}`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        bannerRef={bannerRef}
        title={t("projekti.title")}
        styleImage={true}
        bannerImage={projektiBanner}
        description={t("projekti.description")}
        moveImage={true}
      />
      <SectionNavbar items={items} observeRef={bannerRef} />

      <section className="bg-light container__wrapper" id="oprojektima">
        <div className="container d-flex flex-wrap justify-content-between align-items-center html__text">
          <div className="col-md-7">
            <p className="card_subtitle">{t("projekti.content1")}</p>
            <p className="card_subtitle">{t("projekti.content2")}</p>
          </div>
        </div>
      </section>
      <div className="container__wrapper bg-white" id="projekti">
        <ProjektiLista projekti={projekti} />
      </div>
    </div>
  );
};

export default ProjektiComponent;
