import React, { useRef } from "react";
import { Helmet } from "react-helmet-async";
import BibliotekaLogo from "../../../assets/mef-slike/biblioteka.jpg";
import MainBanner from "../../../components/Baner";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import OsobljeKartice from "./Osoblje";
import { useTranslation } from "react-i18next";
import Kontakt from "./Kontakt";

const BibliotekaComponent = () => {
  const bannerRef = useRef(null);
  const { t } = useTranslation();

  const items = [
    {
      nameKey: "biblioteka.onama",
      link: "#obiblioteci",
    },
    {
      nameKey: "biblioteka.osoblje",
      link: "#biblioteka-osoblje",
    },

    {
      nameKey: "biblioteka.kontakt",
      link: "#biblioteka-kontakt",
    },
  ];

  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("biblioteka.metaTitle")}</title>
        <meta name="description" content={ t("biblioteka.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("biblioteka.metaTitle")}/>
        <meta property="og:description" content={t("biblioteka.description")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <div ref={bannerRef}>
        <MainBanner
          bannerImage={BibliotekaLogo}
          title={t("biblioteka.title")}
          description={t("biblioteka.description")}
          bibliotekaButton={true}
        />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />
      <div className="container__wrapper bg-white" id="obiblioteci">
        <div className="container">
          <p className="html__wrapper">{t("biblioteka.content1")}</p>
          <p className="html__wrapper">{t("biblioteka.content2")}</p>
        </div>
      </div>
      <div className="container__wrapper bg-light" id="biblioteka-osoblje">
        <OsobljeKartice />
      </div>
      <div className="container__wrapper bg-white" id="biblioteka-kontakt">
        <Kontakt />
      </div>
    </>
  );
};

export default BibliotekaComponent;
