import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonComponent from "../../components/ButtonComponent";
import Logo from "../../assets/mef-slike/o-nama-sekcija.jpg";
import { FaCheckCircle } from "react-icons/fa"; // For the checkmark icon
import BlueBannerComponent from "../../components/PlaviBaner";
import style from "./Onama.module.css";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation();

  const features = [
    {
      id: 1,
      title: t(
        "oFakultetuStranica.aboutSection.features.professionalExcellence"
      ),
    },
    {
      id: 2,
      title: t("oFakultetuStranica.aboutSection.features.joyOfLearning"),
    },
    {
      id: 3,
      title: t("oFakultetuStranica.aboutSection.features.autonomyAndStability"),
    },
    {
      id: 4,
      title: t("oFakultetuStranica.aboutSection.features.commitmentToStrategy"),
    },
    {
      id: 5,
      title: t(
        "oFakultetuStranica.aboutSection.features.entrepreneurshipAndInnovation"
      ),
    },
  ];

  return (
    <section className={`${style.sectionStyle} container__wrapper`} id="onama">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Column */}
          <div className="col-lg-6 mb-4 mb-lg-0">
            <img
              src={Logo}
              alt={t("oFakultetuStranica.aboutSection.imageAlt")}
              className={`${style.imageStyle} img-fluid`}
            />
          </div>

          {/* Text Column */}
          <div className="col-lg-6">
            <h2 className={style.titleStyle}>
              {t("oFakultetuStranica.aboutSection.heading")}
            </h2>
            <p className={style.paragraphStyle}>
              {t("oFakultetuStranica.aboutSection.description")}
            </p>
            <ul className="list-unstyled border-bottom">
              {features.map((feature, index) => (
                <li
                  key={index}
                  className="d-flex align-items-center py-2 border-bottom"
                  style={{ borderColor: "#DDDDDD" }}
                >
                  <span className="me-2">
                    <FaCheckCircle color="#0094FF" borderRadius="50%" />
                  </span>
                  <span style={{ fontSize: "16px" }}>{feature.title}</span>
                </li>
              ))}
            </ul>

            <ButtonComponent
              label={t("oFakultetuStranica.aboutSection.historyButton")}
              color="#000"
              backgroundColor="#9FDAF8"
              disabled={true}
            />
          </div>
        </div>
      </div>

      {/* Banner Section */}
      <BlueBannerComponent />
    </section>
  );
};

export default AboutSection;
