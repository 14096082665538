import React, { useEffect, useState } from "react";
import {
  Table,
  InputGroup,
  FormControl,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaDownload, FaTrash, FaEdit } from "react-icons/fa";
import {
  getExamResults,
  deleteExamResult,
  getExamById,
} from "../../../services/userProfileService";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import "./ExamResults.css";
import SharedPagination from "../../../components/SharedPagination";
import AddExam from "./AddExam";

const ExamResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showForm, setShowForm] = useState(false);
  const [editingResult, setEditingResult] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState(null);

  const page = parseInt(searchParams.get("page") || "1", 10);

  const navigate = useNavigate();

  const handleAllResults = () => {
    navigate("/nastava/rezultati-ispita");
  };

  const fetchResults = async () => {
    try {
      setLoading(true);
      const data = await getExamResults(page, searchQuery);
      setResults(data.results);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching results:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [page, searchQuery]);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setSearchParams({ page: newPage });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSearchParams({ page: 1 });
  };

  const handleEdit = async (id) => {
    try {
      setLoading(true);
      const examData = await getExamById(id);
      setEditingResult(examData);
      setShowForm(true);
    } catch (error) {
      toast.error("Greška pri učitavanju rezultata ispita.");
    } finally {
      setLoading(false);
    }
  };

  const handleExamUpdated = () => {
    fetchResults();
    setShowForm(false);
    setEditingResult(null);
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedExamId(id);
    setShowDeleteModal(true);
  };

  const removeExam = async () => {
    if (!selectedExamId) return;
    try {
      setShowDeleteModal(false);
      setLoading(true);
      await deleteExamResult(selectedExamId);
      toast.success("Ispit uspješno obrisan!");
      fetchResults();
    } catch (error) {
      toast.error("Greška pri brisanju ispita.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3" style={{ maxHeight: "max-content" }}>
      <div className=" mb-3">
        <h2>Rezultati ispita</h2>{" "}
        {!showForm && (
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => handleAllResults()}
              variant="success"
              className="me-2"
            >
              Pogledaj rezultate
            </Button>
            <Button onClick={() => setShowForm(true)} variant="primary">
              Dodaj nove rezultate
            </Button>
          </div>
        )}
      </div>

      {showForm ? (
        <AddExam
          onCancel={() => {
            setShowForm(false);
            setEditingResult(null);
          }}
          existingData={editingResult}
          onExamUpdate={handleExamUpdated}
        />
      ) : (
        <>
          <InputGroup className="mb-3 exam-search">
            <FormControl
              placeholder="Pretraži po predmetu ili roku"
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>

          <div style={{ overflow: "auto" }}>
            <Table bordered hover className="bg-white shadow-sm exam-table">
              <thead>
                <tr>
                  <th>Predmet</th>
                  <th>Rok</th>
                  <th>Datum ispita</th>
                  <th>Datum objave</th>
                  <th>Preuzmi</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      <Spinner animation="border" />
                    </td>
                  </tr>
                ) : results.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      Nema objavljenih ispita.
                    </td>
                  </tr>
                ) : (
                  results.map((result) => (
                    <tr key={result.id}>
                      <td className="subject">{result.subject}</td>
                      <td>{result.term}</td>
                      <td>{result.exam_date}</td>
                      <td>{result.result_date}</td>
                      <td className="text-center">
                        <a
                          href={result.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "black" }}
                        >
                          <FaDownload />
                        </a>
                      </td>
                      <td className="text-center d-flex align-center justify-content-center">
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="me-2"
                          onClick={() => handleDeleteConfirmation(result.id)}
                        >
                          <FaTrash />
                        </Button>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleEdit(result.id)}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          <SharedPagination
            currentPage={page}
            totalPages={Math.ceil(count / 10)}
            onPageChange={handlePageChange}
          />
        </>
      )}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Body className="text-center exam-modal">
          <h5>Jeste li sigurni da želite izbrisati ispit?</h5>
        </Modal.Body>
        <Modal.Footer className="exam-footer">
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Otkaži
          </Button>
          <Button variant="danger" onClick={removeExam}>
            Da, izbriši
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExamResults;
