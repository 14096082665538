import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./LanguageSwitcher.module.css";

const LanguageSwitcher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang); // i18next will handle storing the language in localStorage
  };

  const isEnglish = i18n.language === "en";

  return (
    <div className={styles.languageSwitcherContainer}>
      <div className={styles.languageButtons}>
        <button
          onClick={() => switchLanguage("bs")}
          className={`${styles.languageButton} ${
            !isEnglish ? styles.active : ""
          }`}
        >
          BS
        </button>
        
        <button
          onClick={() => switchLanguage("en")}
          className={`${styles.languageButton} ${
            isEnglish ? styles.active : ""
          }`}
        >
          EN
        </button>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
