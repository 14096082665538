import React from "react";
import "../styles/global.css";
import ButtonComponent from "./ButtonComponent";
import { Link } from "react-router-dom";
import style from "./CiklusiKomponenta.module.css";
import { useTranslation } from "react-i18next";

const CycleComponent = () => {
  const { t, i18n } = useTranslation();

  const cycles = [
    {
      title: t("oFakultetuStranica.facultyCycles.firstCycleTitle"),
      slug: "prvi-ciklus-studija",
      description: t("oFakultetuStranica.facultyCycles.firstCycleDescription"),
    },
    {
      title: t("oFakultetuStranica.facultyCycles.secondCycleTitle"),
      slug: "drugi-ciklus-studija",
      description: t("oFakultetuStranica.facultyCycles.secondCycleDescription"),
    },
    {
      title: t("oFakultetuStranica.facultyCycles.thirdCycleTitle"),
      slug: "treci-ciklus-studija",
      description: t("oFakultetuStranica.facultyCycles.thirdCycleDescription"),
    },
  ];

  return (
    <div className="bg-white" id="ciklusi">
      <div>
        <div className="row g-2">
          {cycles.map((cycle, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-12"
              style={{ cursor: "pointer" }}
            >
              <div
                className={`${style.cycleWrapper} d-flex flex-column justify-content-between`}
              >
                <h4 className="card__title">{cycle.title}</h4>
                <p className="card__subtitle">{cycle.description}</p>

                <Link
                  to={`/nastava/${cycle.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <ButtonComponent
                    label={t("oFakultetuStranica.facultyCycles.learnMore")}
                    color="#000"
                    backgroundColor="#9FDAF8"
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CycleComponent;
