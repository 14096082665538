import React, { useState, useEffect, useRef } from "react";
import { Accordion, Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { fetchFacultyHistory } from "../../../services/apiService";
import style from "./Historija.module.css";

const TimeLine = () => {
  const { t, i18n } = useTranslation();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const accordionRef = useRef(null);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setLoading(true);
        const data = await fetchFacultyHistory();
        setHistory(data);
      } catch (error) {
        console.error("Error fetching history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [i18n.language]);

  const handleAccordionClick = (index) => {
    setTimeout(() => {
      const activeItem = document.getElementById(`accordion-item-${index}`);
      if (activeItem) {
        const offset = 200;
        const itemPosition =
          activeItem.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: itemPosition - offset, behavior: "smooth" });
      }
    }, 300);
  };

  return (
    <div className="container mb-4">
      <div
        className="d-flex align-items-center pb-3 mb-4 col-lg-8"
        style={{ borderBottom: "1px solid #DDDDDD" }}
      >
        <div className={style.headerCycleWrapper}></div>
        <h2 className="fs-6 fw-medium mb-0">{t("historija.timeline")}</h2>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="col-lg-8">
        <Accordion defaultActiveKey="" ref={accordionRef}>
          {history.map((entry, index) => (
            <Accordion.Item
              eventKey={index.toString()}
              key={index}
              id={`accordion-item-${index}`}
            >
              <Accordion.Header onClick={() => handleAccordionClick(index)}>
                <h5 className="mb-0 ms-auto">{entry.title}</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Card className={`p-3 ${style.html__timeline}`} style={{ overflow: "hidden" }}>
                  <div dangerouslySetInnerHTML={{ __html: entry.content }} />
                </Card>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
           </div>
      )}
    </div>
  );
};

export default TimeLine;
