import React from "react";
import JavneNabavkeBanner from "../../../assets/mef-slike/nabavke.jpg";
import MainBanner from "../../../components/Baner";
import { useTranslation } from "react-i18next";
import style from "./JavneNabavke.module.css";
import { Helmet } from "react-helmet-async";

const JavneNabavkeComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <title>{t("javneNabavke.metaTitle")}</title>
        <meta name="description" content={ t("javneNabavke.metaDescription")} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={t("javneNabavke.metaTitle")}/>
        <meta property="og:description" content={t("javneNabavke.description")}  />
        <meta property="og:image" content={`${window.location.origin}/logo220.png`} />
        <meta property="og:url" content={window.location.href}/>
      </Helmet>

      <MainBanner
        bannerImage={JavneNabavkeBanner}
        title={t("javneNabavke.title")}
        description={t("javneNabavke.description")}
      />
      <div className="bg-white container__wrapper">
        <div className="container">
            <p className="mt-4">
              {t("javneNabavke.content")} 
              <a
                href="https://www.anticorrupiks.com/institucija/2CDCFFCA-DCE8-E24A-AE45-FA05DEBD7B6C/registar-javnih-nabavki"
                className={style.linkStyle}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("javneNabavke.linkDescription")}
              </a>.
            </p>
            <iframe
              src="https://www.anticorrupiks.com/institucija/2CDCFFCA-DCE8-E24A-AE45-FA05DEBD7B6C/registar-javnih-nabavki"
              className={style.iframeStyle}
              title="Javne Nabavke"
            ></iframe>
          </div>

      </div>
    </>
  );
};

export default JavneNabavkeComponent;
